import Coustomers from "./customer";


export default function Customer() {
   
    return (
       <>
        <Coustomers/>
       </>
 
    );
 }