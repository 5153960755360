import React from "react";
import "./privacy.css";
import { Link } from "react-router-dom";
import Header from "../Header/index";
import Footer from "../Footer/index"
import { Helmet } from "react-helmet";
import Logo from "./images/Billnama_logo_bg_1024X1024 (1).jpg"
export default function Datadeletion() {
    return (
        <>
        <Helmet>
                <title> Data Deletion Instructions | BillNama</title>
                <meta
                    name="description"
                    content=" Follow our data deletion instructions to securely remove your information from BillNama's servers if needed, in compliance with data protection regulations."
                    />
                <link rel="canonical" href="https://billnama.com/data-deletion-instruction" />
            </Helmet>
        <Header/>
            <head >
                <meta property="og:url" content="https://www.softnoesis.com/apps/invoice/privacy_policy.php" />
                <meta property="fb:app_id" content="1515597091873107" />
                <meta property="og:title" content="Privacy Policy: BillNama, Bill Generator" />
                <meta property="og:description" content="BillNama, Bill Generator" />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="Privacy Policy: BillNama, Bill Generator" />
                <meta property="og:image" content="https://is2-ssl.mzstatic.com/image/thumb/Purple122/v4/11/00/66/11006672-0614-b999-d907-5c7ca9bdfedf/AppIcon-1x_U007emarketing-0-7-0-85-220.png/246x0w.webp" />
            </head>
            <div class="context">
        <h1>Data Deletion Instruction</h1>
    </div>


<div class="area" >
            <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
    </div >
            <div id="privacy_police" style={{ padding: '20px', marginLeft: '5%', marginRight: '5%' }} >
                <h2>Data Deletion Instruction for "BillNama :<br/> Generate bill"</h2>
                <img src={Logo}/>
                {/* <img src="https://is2-ssl.mzstatic.com/image/thumb/Purple122/v4/11/00/66/11006672-0614-b999-d907-5c7ca9bdfedf/AppIcon-1x_U007emarketing-0-7-0-85-220.png/246x0w.webp" alt="Invoice maker application" /> */}
                <br />
                
                <p>Last updated: 5 May, 2022</p>

                <p>Please read these instructions prior to deleting your data from our data storage. Just mind that We are using Google Firebase service for data storage.</p>

                <p>1. First send an email on support@billnama.com. Your email must have the subject as this "Remove Invoice app data from Firebase.". Its body should also contain a registered email id or phone number.</p>

                <p>2. Our team will respond to you as soon as possible by mail and give you acknowledgement that we will start processing</p>

                <p>3. As soon as it is finished, we will send you confirmation mail.</p>

                <p>4. It is a kind request that you should also acknowledge us that you read mail about success.</p>
                
                <h3>How To Contact Us</h3>
                <p>You can contact us 24 * 7 hours via support@billnama.com<br/>You will be replied as soon as possible.</p>
                
                <p>Appstore: <Link href="https://apps.apple.com/app/invoice-maker-bill-generator/id1444998056" target="_blank" style={{color:"blue"}}>https://apps.apple.com/app/invoice-maker-bill-generator/id1444998056</Link></p>
                <p>Playstore: <Link href="https://play.google.com/store/apps/details?id=com.softnoesis.invoice" target="_blank" style={{color:"blue"}}> https://play.google.com/store/apps/details?id=com.softnoesis.invoice</Link></p>
            </div>
            <Footer/>
        </>
    );
}