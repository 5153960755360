import './Invoice.css';
import { Link, json } from 'react-router-dom';
import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useReactToPrint } from 'react-to-print';
import firebase, { database, get, child } from '../Login/config';
import ReactDOM from 'react-dom';
import SignatureCanvas from 'react-signature-canvas';


export default function Preview() {
  const company_ids = localStorage.getItem("selectedCompanyId");
  const companyName = localStorage.getItem("companyName");
  const address = localStorage.getItem("address");
  const com_emil = localStorage.getItem("com_emil");
  const UserId = localStorage.getItem("AccessUserId");
  const customer = localStorage.getItem("customer");
  const cus_invoice_number = localStorage.getItem("cus_invoice_number");
  const customerGSTNo = localStorage.getItem("customerGSTNo");
  const dueDate = localStorage.getItem("dueDate");
  // const address = localStorage.getItem("address");
  const phone = localStorage.getItem("phone");
  const email = localStorage.getItem("email");
  const cus_note = localStorage.getItem("cus_note");
  const com_terms = localStorage.getItem("com_terms");
  const com_country = localStorage.getItem("com_country")
  const Discount_amount = localStorage.getItem("DiscountAmounts");
  const Logo = localStorage.getItem("companyPhoto");
  var ProductData = JSON.parse(localStorage.getItem("ProductData"));
  const Discount_pr = localStorage.getItem("discount");
  const Tax_pr = localStorage.getItem("taxvalue");
  const Growprice = localStorage.getItem("Growprice");
  const Gst = localStorage.getItem("Gst");
  const totalAmount = localStorage.getItem("totalAmount");


  // const selectedCompanyIds = localStorage.getItem("selectedCompanyId");
  // console.log('company_id', company_ids)
  let Showitems;
  let ShowitemsFirebase;

  // pdf print start
  const userpdf = useRef();
  const genretpdf = useReactToPrint({
    content: () => userpdf.current,
    documentTitle: "Userdata",
  });
  // pdf print End

  // Start code getdata using firbase.
  const [datas, setData] = useState();
  const [iteamData, setIteamData] = useState();
  const [company, setCompany] = useState();
  let bildata = [];
  let bildatas = [];

  const getbilinvoice = () => {

    get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {

      if (snapshot.exists()) {

        bildata = snapshot.val().billInvoice;
        const data = snapshot.val();
        setData(Object.entries(data.billInvoice));
        setCompany(Object.entries(data.company));
        setIteamData(Object.entries(data.billInvoice.items));
        // console.log('first', data.billInvoice.items[0].amt)

      }

    }).catch((error) => {
      console.error(error);
    });

  }

  useEffect(() => {
    getbilinvoice();
  }, []);

  return (
    <>
     <div id='Spcae'>
          <h2>Hello</h2>
    </div>
      {UserId &&
        <div className='Invoice'>
          <Link to="/">
              <div className='back'>
                <i className="fa-sharp fa-solid fa-xmark"></i>
              </div>
            </Link>
          <div className='Invoices'ref={userpdf} >

            <div className='Invoice-details' ref={userpdf}>

              {/* Company details code start  */}
              {company ? company.map((company_data, i) => {

                // console.log('firstlocal', company_ids)
                if (company_data[1].company_id === company_ids) {
                  return (
                    <div className='bussiness-info'>
                      <div className='bussiness-info-logo'>
                        <img src={company_data[1].companyPhoto} />
                      </div>
                      <div className='bussiness-info-details'>
                        <h3>{company_data[1].companyName}</h3>
                        <p> <b>GSTIN :-</b>{company_data[1].gstNumber}</p>
                        <p className='adress'><b>Address :- </b> {company_data[1].address}</p>
                      </div>
                    </div>
                  )
                }
              }) : "Company Data Not Found"}
              {/* Company details code End  */}

              {/* divider start */}
              <div className='divider'>
                <hr />
              </div>
              {/* divider End */}

              {/* customer-info start */}

              {datas ? datas.map((invo_data, index) => {
                // { console.log('first', invo_data[1].items[0].amt) }
                if (index === datas.length - 1) {
                  return (
                    <>
                      <div className="customer-info" key={index}>
                        <div className='Cusname-invoice-number'>
                          <div className='Cus-name'>
                            <span>  To, <span>{invo_data[1].customer}</span></span>
                          </div>
                          <div className='invoice-number'>
                            <span>Invoice: <span>{invo_data[1].cus_invoice_number}</span></span>
                          </div>
                        </div>
                        <div className='Gst-date'>
                          <div className='Gst'>
                            <span>GSTIN: <span>{invo_data[1].customerGSTNo}</span></span>
                          </div>
                          <div className='Date'>
                            <span>Date: <span>{invo_data[1].dueDate}</span></span>
                          </div>
                        </div>

                        <div className='Adress'>
                          <p>{invo_data[1].address}</p>
                        </div>
                        <div className='number'>
                          <span>MO: <span>{invo_data[1].phone}</span></span>
                        </div>

                        <div className='Email'>
                          <span>Email: <span>{invo_data[1].email}</span></span>
                        </div>

                      </div>

                      <div className='purshcing-info'>
                        <div className='purshcing-info-heading'>
                          <div className='no-item'>
                            <div className='no'>
                              <p>Item</p>
                            </div>
                            <div className='item'>
                              <p>Description</p>
                            </div>
                          </div>

                          <div className='price-amount'>
                            <div className='no'>
                              <p>Qty</p>
                            </div>
                            <div className='item'>
                              <p>Price</p>
                            </div>
                            <div className='item'>
                              <p>Amount</p>
                            </div>
                          </div>
                        </div>
                        {/* purshcing-info-section1 Start*/}


                        {invo_data[1].items.map((items, i) => {
                          return (
                            <ol type=''>
                              <li>
                                <div className='purshcing-info-section1'>
                                  <div className='no-item'>
                                    <div className='no'>
                                      <p>{items.item}</p>
                                    </div>
                                    <div className='item'>
                                      <p>{items.dse}</p>
                                    </div>
                                  </div>
                                  <div className='price-amount'>
                                    <div className='Qty'>
                                      <p>{items.qty}</p>
                                    </div>
                                    <div className='Price'>
                                      <span>₹<span>{items.price}</span></span>
                                    </div>
                                    <div className='Amount'>
                                      <span>₹<span>{items.amt}</span></span>
                                    </div>
                                  </div>
                                </div>
                              </li>
                            </ol>)
                        })
                        }
                        {/* purshcing-info-section1  End*/}

                        {/* divider start */}
                        <div className='divider'>
                          {/* <hr /> */}
                        </div>
                        {/* divider End */}

                        {/* purshcing-info-section3 Start*/}
                        <div className='iteam-total'>
                          <div className='total-Amount'>
                            <span>₹<span>{invo_data[1].total}</span></span>
                          </div>
                        </div>
                        {/* purshcing-info-section3  End*/}

                        {/* divider start */}
                        <div className='divider'>
                          <hr />
                        </div>
                        {/* divider End */}
                        <div>
                          <div className='Sub-Gst'>
                            <div className='CGST'>
                              <span>GST (<span>{invo_data[1].gstPercetage+"%"} </span>) :</span>
                            </div>
                            <div className='CGST-price'>
                              <span><span>{invo_data[1].gstAmount}</span></span>
                            </div>
                          </div>
                          <div className='Sub-Gst'>
                            <div className='Disc'>
                              <span>Disc (<span>{invo_data[1].discountPercetage + "%"}</span>)  :</span>
                            </div>
                            <div className='Disc-price'>
                              <span>₹<span>{invo_data[1].discountAmount}</span></span>
                            </div>
                          </div>
                          <div className='Sub-Gst'>
                            <div className='Net-amount'>
                              <span><b>Payable Amount</b></span>
                            </div>
                            <div className='Net-price'>
                              <span><b>₹<span>{invo_data[1].totalAmount}</span></b></span>
                            </div>
                          </div>
                        </div>
                        <div className='Note'>
                          <span><b>Note: </b></span>
                          <span>{invo_data[1].note}</span>
                        </div>
                        <div className="signeture-mens">
                          <div className='Mens'>
                            <p>Men's</p>
                          </div>
                          <div className='signature'>
                            <div className='sig-box'>

                            </div>
                            <p>Signature of Company</p>
                          </div>
                        </div>
                        {/* divider start */}
                        <div className='divider'>
                          <hr />
                        </div>
                        {/* divider End */}
                      </div>
                    </>
                  )
                }
              }) : "testdata"}

              {/* customer-info Code End */}



              <div className='Tearms-section'>
                <div className='Tearms'>
                  <span>Terms:</span>
                  <ul>
                    <li></li>
                  </ul>
                </div>
                <div className='Reciver-sig'>
                  <div className='signature'>
                    <div className='sig-box'>
                    </div>
                    <p>Receiver Signature</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        || <div className='Invoice1' ref={userpdf}>
          <Link to="/">
            <div className='back'>
              <i className="fa-sharp fa-solid fa-xmark"></i>
            </div>
          </Link>

          <div className='Invoice-details'>
            <div className='bussiness-info'>
              <div className='bussiness-info-logo'>
                <img src={Logo} />
              </div>
              <div className='bussiness-info-details'>
                <h3>{companyName}</h3>
                <p>{com_country}</p>
                <p> {com_emil}</p>
                <p className='adress'><b>Address:</b> {address}</p>
              </div>
            </div>
            {/* divider start */}
            <div className='divider'>
              <hr />
            </div>
            {/* divider End */}
            <div className="customer-info">{/* customer-info start */}
              <div className='Cusname-invoice-number'>
                <div className='Cus-name'>
                  <span>  To, <span>{customer}</span></span>
                </div>
                <div className='invoice-number'>
                  <span>Invoice:<span>{cus_invoice_number}</span></span>
                </div>
              </div>
              <div className='Gst-date'>
                <div className='Gst'>
                  <span> GSTIN:<span>{customerGSTNo}</span></span>
                </div>
                <div className='Date'>
                  <span>Date:<span>{dueDate}</span></span>
                </div>
              </div>
              <div className='Adress'>
                <p>{address}</p>
              </div>
              <div className='number'>
                <span>MO: <span>{phone}</span></span>
              </div>
              <div className='Email'>
                <span>Email: <span>{email}</span></span>
              </div>
            </div>
            {/* customer-info End */}
            <div className='purshcing-info'>
              <div className='purshcing-info-heading'>
                <div className='no-item'>
                  <div className='no'>
                    <p>Item</p>
                  </div>
                  <div className='item'>
                    <p>Description</p>
                  </div>
                </div>
                <div className='price-amount'>
                  <div className='no'>
                    <p>Qty</p>
                  </div>
                  <div className='item'>
                    <p>Price</p>
                  </div>
                  <div className='item'>
                    <p>Amount</p>
                  </div>
                </div>
              </div>
              {/* purshcing-info-section1 Start*/}
              {Showitems = ProductData.map((element) => {
                return (<ol>
                  <li>
                    <div className='purshcing-info-section1'>
                      <div className='no-item'>
                        <div className='no'>
                          <p>{element.item}</p>
                        </div>
                        <div className='item'>
                          <p>{element.dse}</p>
                        </div>
                      </div>
                      <div className='price-amount'>
                        <div className='Qty'>
                          <p>{element.qty}</p>
                        </div>
                        <div className='Price'>
                          <span>₹<span>{element.price}</span></span>
                        </div>
                        <div className='Amount'>
                          <span>₹<span>{element.amt}</span></span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ol>)
              })}
              {/* purshcing-info-section1  End*/}

              {/* divider start */}
              <div className='divider'>
                <hr />
              </div>
              {/* divider End */}

              {/* purshcing-info-section3 Start*/}
              <div className='iteam-total'>
                {/* <div className='total-Qty'>
                <p>07</p>
              </div> */}
                <div className='total-Amount'>
                  <span>₹<span>{Growprice}</span></span>
                </div>
              </div>
              {/* purshcing-info-section3  End*/}

              {/* divider start */}
              <div className='divider'>
                <hr />
              </div>
              {/* divider End */}
              <div>
                <div className='Sub-Gst'>
                  <div className='CGST'>
                    <span>GST <span> ({Tax_pr}%) :</span></span>
                  </div>
                  <div className='CGST-price'>
                    <span><span>{Gst}</span></span>
                  </div>
                </div>
                {/* <div className='Sub-Gst'>
              <div className='SGST'>
                  <span>SGST<span>(2.5%) :</span></span>
              </div>
              <div className='SGST-price'>
                <span>₹<span>100</span></span>
              </div>
          </div> */}
                <div className='Sub-Gst'>
                  <div className='Disc'>
                    <span>Disc <span> ({Discount_pr}%): </span></span>
                  </div>
                  <div className='Disc-price'>
                    <span><span>{Discount_amount}</span></span>
                  </div>
                </div>
                <div className='Sub-Gst'>
                  <div className='Net-amount'>
                    <span><b>Payable Amount</b></span>
                  </div>
                  <div className='Net-price'>
                    <span><b><span>{totalAmount}</span></b></span>
                  </div>
                </div>
              </div>
              <div className='Note'>
                <span><b>Note: </b></span>
                <span>{cus_note} </span>
              </div>
              <div className="signeture-mens">
                <div className='Mens'>
                  <p>Men's</p>
                </div>
                <div className='signature'>
                  <div className='sig-box'>

                  </div>
                  <p>Signature of Company</p>
                </div>
              </div>
              {/* divider start */}
              <div className='divider'>
                <hr />
              </div>
              {/* divider End */}
            </div>
            <div className='Tearms-section'>
              <div className='Tearms'>
                <span>Terms:</span>
                <ul>
                  <li>{com_terms}</li>
                  {/* <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li>
                <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</li> */}
                </ul>
              </div>
              <div className='Reciver-sig'>
                <div className='signature'>
                  <div className='sig-box'>
                  </div>
                  <p>Receiver Signature</p>
                </div>
              </div>
            </div>
          </div>
        </div>}
      <button id="pdf" onClick={genretpdf}><i className="fa-solid fa-cloud-arrow-down"></i> Download</button>
    </>
  );
}