import firebase from "firebase/compat/app";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from "firebase/auth";
import { useEffect } from "react";
import { ref  } from "firebase/database";
import { getDatabase, child, get, update, remove, set } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getMessaging, getToken } from 'firebase/messaging';
import getFirestore from "firebase/firestore"

const firebaseConfig = {
  // apiKey: "AIzaSyASseNBpCRespWd_kYvzIJfeDR2WqgAKuw",
  // authDomain: "fir-final-e90a8.firebaseapp.com",
  // databaseURL: "https://fir-final-e90a8-default-rtdb.firebaseio.com",
  // projectId: "fir-final-e90a8",
  // storageBucket: "fir-final-e90a8.appspot.com",
  // messagingSenderId: "169870703853",
  // appId: "1:169870703853:web:eddbebdd8cbab89ccb60e4"

  // apiKey: "AIzaSyD2leR3xuyFIyTNm-MYJ1ahTP47yDlO96s",
  // authDomain: "softnoesis-7e28b.firebaseapp.com",
  // databaseURL: "https://softnoesis-7e28b.firebaseio.com",
  // projectId: "softnoesis-7e28b",
  // storageBucket: "softnoesis-7e28b.appspot.com",
  // messagingSenderId: "244596601598",
  // appId: "1:244596601598:web:a00ff2158df3d907f5abaf",
  // measurementId: "G-HPZ5S15KKT"

  apiKey: "AIzaSyD41WE04ZjmymBzAudLExcyXoaHiLYpk38",
  authDomain: "softnoesis-7e28b.firebaseapp.com",
  databaseURL: "https://softnoesis-7e28b.firebaseio.com",
  projectId: "softnoesis-7e28b",
  storageBucket: "softnoesis-7e28b.appspot.com",
  messagingSenderId: "244596601598",
  appId: "1:244596601598:web:a00ff2158df3d907f5abaf",
  measurementId: "G-HPZ5S15KKT"

  // apiKey: "AIzaSyASseNBpCRespWd_kYvzIJfeDR2WqgAKuw",
  // authDomain: "fir-final-e90a8.firebaseapp.com",
  // databaseURL: "https://fir-final-e90a8-default-rtdb.firebaseio.com",
  // projectId: "fir-final-e90a8",
  // storageBucket: "fir-final-e90a8.appspot.com",
  // messagingSenderId: "169870703853",
  // appId: "1:169870703853:web:cfde97490b6142b4cb60e4"

};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = ref(getDatabase(app));
// console.log("dbRef", database);
const auth = getAuth(app);
const databaseurl = firebaseConfig.databaseURL;
const provider = new GoogleAuthProvider();
console.log('first', provider)
const facebookProvider = new FacebookAuthProvider();
const imgDb = getStorage(app);
// Get Firebase Storage instance
const storage = getStorage(app);

// Get Firebase Realtime Database instance

export { auth, provider, facebookProvider, database,storage, child, get, set, ref, imgDb, update, remove, getDatabase,app,databaseurl };

if (firebase.app.length === 0) {
  firebase.initializeApp(firebaseConfig);
}
export default firebase;
