import './loader.css';

export default function Loader() {
    return (
        <>
        <div id='loader'>
            <div className="typewriter">
                <div className="slide"><i></i></div>
                <div className="paper"></div>
                <div className="keyboard"></div>
            </div>
            </div>
        </>
    );
}