import './Cusform.css';
import React, { useEffect, useState } from 'react';
import Footer from '../Footer/index';
import firebase, { child, database, get, storage, useFirebase,databaseurl } from '../Login/config';
// import { ref } from "firebase/database";
// import ConfirmBox from "react-dialog-confirm";
// import { Textbox, Radiobox, Checkbox, Select, Textarea } from 'react-inputs-validation';
import Header from '../Header/index';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { Navigate, useNavigate } from 'react-router-dom';


export default function Rate() {

  // Get Company Data Code Start.

  const UserId = localStorage.getItem("AccessUserId");
  const companyName = localStorage.getItem("companyName");
  const [companydata, setCompany] = useState();
  let bildata = [];
  const [CompanyId, setCompanyId] = useState("");
  const getcompany = () => {
    get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
      if (snapshot.exists()) {
        bildata = snapshot.val().billInvoice;
        const data = snapshot.val();
        const companies = Object.entries(data.company);
        setCompany(companies);

        for (let index = 0; index < companies.length; index++) {
          setCompanyId(companies[index][1].companyId)
          // console.log('testdata', CompanyId)
        }
      }
    }).catch((error) => {
      console.error(error);
    });
  };
  useEffect(() => {
    getcompany();
    // console.log('testdata', CompanyId)
  }, [CompanyId]);

  // Get Company Data Code End.


  const UpdateInvoiceId = localStorage.getItem("Update_Invoice_Id");

  // Apply tax code start

  const [Taxs, setTax] = useState('');
  let Symbol = localStorage.getItem("symbol");
  let CurrencyCode = localStorage.getItem("currencyCode");
  const TaxType = () => {
    if (CurrencyCode == "EUR" && CurrencyCode == "CZK" && CurrencyCode == "DKK" && CurrencyCode == "HUF" && CurrencyCode == "PLN" && CurrencyCode == "RON" && CurrencyCode == "SEK" && CurrencyCode == "GBP") {
      setTax("VAT");
    } else if (CurrencyCode == "INR") {
      setTax("GST");
    }
    else {
      setTax("TAX");
    }
  }
  useEffect(() => {
    TaxType();
  }, [Taxs]);
  const [taxvalue, settaxvalue] = useState("");
  const handleClick = (value) => {
    settaxvalue(value);
  };
  const [discount, setdiscount] = useState("");
  const handleClicks = (value) => {
    setdiscount(value);
  };

  // Apply tax code end

  const UserID = localStorage.getItem("AccessUserId");
  const venderId = Math.floor(Math.random() * 9000000000000) + 1;
  const companyId = CompanyId != "undefined" ? CompanyId : venderId + 1500920;
  const invoiceId = Date.now();
  const date = new Date();
  const Day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const Year = date.getFullYear();
  const creationDate = Day + "  " + month + ", " + Year;

  // add iteam Fun start
  const [items, setitems] = useState([{ item: "", dse: "", qty: "", price: "", amt: "" }]);
  const add_iteam = () => {
    setitems([...items, { item: "", dse: "", qty: "", price: "", amt: "" }]);
  }
  const remove_iteam = (index) => {
    const list = [...items];
    list.splice(index, 1);
    setitems([...list]);
  }

  let total = 0;

  const [userdata, SetUserdata] = useState({
    customer: "",
    email: "",
    cus_invoice_number: "",
    address: "",
    dueDate: "",
    cus_city: "",
    customerGSTNo: "",
    phone: "",
    note: "",
  })

  // Validation code start....
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    console.log('items', items)
    // Validate name
    if (!userdata.customer) {
      newErrors.customer = 'Customer Name is required';
      isValid = false;
    }

    if (!items[0].item) {
      newErrors.item = 'Item Name is required';
      isValid = false;
    }

    if (!items[0].qty) {
      newErrors.qty = 'one quantity  is required';
      isValid = false;
    }
    if (!items[0].price) {
      newErrors.price = 'Rate is required';
      isValid = false;
    }


    setErrors(newErrors);
    return isValid;
  };
  // Validation code end....


  const postUserData = (e) => {
    // SetUserdata({ ...userdata, [name]: value });
    SetUserdata({ ...userdata, [e.target.name]: e.target.value });
  }

  // tax and discount code start

  const TotalAmount = localStorage.getItem("total");
  const Growprice = parseInt(TotalAmount);
  const GST = (Growprice * taxvalue / 100);
  const gstAmount = GST;
  const gstPercetage = taxvalue;
  const TaxAmount = (Growprice + GST).toFixed(2);;
  const discountAmount = (TaxAmount * discount / 100).toFixed(2);
  const discountPercetage = discount;
  localStorage.setItem("DiscountAmounts", discountAmount);
  const totalAmount = (Growprice + GST - discountAmount).toFixed(2);
  localStorage.setItem("totalAmount", totalAmount);

  // tax and discount code end

  useEffect(() => {

  }, [userdata]);

  const submitedata = async (event) => {

    event.preventDefault();
    localStorage.setItem("ProductData", JSON.stringify(items));
    localStorage.setItem("Growprice", parseInt(total).toFixed(2));
    localStorage.setItem("Gst", GST);
    const { customer, email, cus_invoice_number, dueDate, address, customerGSTNo, phone
      , com_signature, note } = userdata;

    // company data store code start
    if (validateForm()) {
      const res = await fetch(`${databaseurl}/invoiceGenerator/users/${UserID}/billInvoice.json`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customer, email,  address, dueDate, customerGSTNo, phone
            , items, companyId, invoiceId, total, gstAmount, gstPercetage, discountAmount, discountPercetage, totalAmount, creationDate, note,
          }),
        }
      );
      // company data store code end
      // session data store code start

      // session  data
      if (validateForm()) {
        await fetch(`${databaseurl}/invoiceGenerator/users/${UserID}/session/${venderId}.json`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              browserName, venderId, creationDate
            }),
          }
        );
      }
      // session data store code end
      window.location = "/simple-invoice-template";
    } else {
      console.log('Data Not Stored', "Data Not Stored")
    }

  };

  //  Set browsername in firebase and localstorage code start

  function getBrowserName(userAgent) {
    // The order matters here, and this may report false positives for unlisted browsers.
    if (userAgent.includes("Firefox")) {
      return "Mozilla Firefox";
    } else if (userAgent.includes("SamsungBrowser")) {
      return "Samsung Internet";
    } else if (userAgent.includes("Opera") && userAgent.includes("OPR")) {
      return "Opera";
    } else if (userAgent.includes("Edge")) {
      return "Microsoft Edge (Legacy)";
    } else if (userAgent.includes("Edg")) {
      return "Microsoft Edge (Chromium)";
    } else if (userAgent.includes("Chrome")) {
      return "Google Chrome or Chromium";
    } else if (userAgent.includes("Safari")) {
      return "Apple Safari";
    } else {
      return "unknown";
    }
  }
  const browserName = getBrowserName(navigator.userAgent);

  //  Set browsername in firebase and localstorage code end

  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }
  return (
    <>
      <Header />
      <div className='Form'>

        <form onSubmit={submitedata}>

          <div className='compnay_details'>
            {UserId &&
              <>

              </>
              || companyName &&
              <div className='bussiness' >
                <div>
                  <div className="Compny">
                    <div className='Compny_info'>
                      <div className="compny_left">
                        <div className='compny_details_logo'>
                          {/* <img src={Logo} /> */}
                        </div>
                        <div className='compny_info_details'>
                          <h3>{companyName}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              || "No Records found"}
          </div>

          <div className='Bill-item' id='Bill-item'>
            <div className='items_heading'>
              <div className='items_heding'>
                <span>Item Name</span>
              </div>
              <div className='items_fulldetails'>
              <p>
                <select>
                  <option>Quantity</option>
                  <option>Hours</option>
                </select>
                </p>
                {/* Quantity / Hours */}
                <p>Rate</p>
                <p>AMOUNT</p>
              </div>
            </div>

            {items.map((item, index) => {
              total = total + item.amt;
              localStorage.setItem("total", total);
              return (
                <>
                  <div className='Add-item' id='add-iteam'>

                    <div className='Item'>
                      <input value={item.item}
                        onChange={(elemet) => {
                          const list = [...items];
                          list[index].item = elemet.target.value;
                          setitems(list);
                          // setitems({ ...list, [elemet.target.name]: elemet.target.value });
                        }}
                        name="item"
                        placeholder='Enter Item Name'
                      />
                      {errors.item && <span >{"⚠" + " " + errors.item}</span>}
                    </div>
                    <div className='Quantity'>
                      <input type='number' min="0" value={item.qty}
                        onChange={(elemet) => {
                          const list = [...items];
                          list[index].qty = elemet.target.value;
                          setitems(list);
                        }}
                        onWheel={event => event.currentTarget.blur()}
                        name='qty'
                        placeholder='0' />
                      {errors.qty && <span >{"⚠" + " " + errors.qty}</span>}
                      {/* <label>Quantity/Hours*</label> */}
                    </div>
                    <div className='Price'>
                      <input type='number' min="0" value={item.price}
                        onChange={(elemet) => {
                          const list = [...items];
                          list[index].price = elemet.target.value;
                          list[index].amt = list[index].qty * elemet.target.value;
                          setitems(list);
                        }}
                        onWheel={event => event.currentTarget.blur()}
                        name='price'
                        placeholder='00.00' />
                      {errors.price && <span >{"⚠" + " " + errors.price}</span>}
                      {/* <label>Rate*</label> */}
                    </div>
                    <div className='Grows-price' id="amount">
                      {/* <p >Amount</p> */}
                      <span>{item.price * item.qty || item.qty * item.price}</span>
                    </div>
                    {index == 0 ? (<div className='remove' style={{ visibility: "hidden" }}>
                      <i className="fa-solid fa-xmark" onClick={() => remove_iteam(index)}></i>
                    </div>) : (<div className='remove'>
                      <i className="fa-solid fa-xmark" onClick={() => remove_iteam(index)}></i>
                    </div>)}
                  </div>

                  <div>
                    <div className='Description' id='Description'>
                      <input value={item.dse}
                        onChange={(elemet) => {
                          const list = [...items];
                          list[index].dse = elemet.target.value;
                          setitems(list);
                        }}
                        placeholder='Enter Item Details' />
                      {/* <label>Details*</label> */}
                    </div>
                  </div>
                </>
              );
            })}
            <div className='new-add' id='new-add'>
              <button type="button" onClick={add_iteam}>
                <span className='pluse'>+</span>
                <span>Add a New item</span>
              </button>
            </div>
          </div>


          <div className='Total-price'>
            <div className='Total-price-details'>
              <div className='Price'>
                <p>Gross Amount</p>
                <p>{Symbol} {total} </p>
              </div>
              <div className='gst'>
                <p>{Taxs} </p>
                <p>
                  <b>
                    <select onChange={(e) => { handleClick(e.currentTarget.value) }}>{Array.from(Array(100), (e, i) => {
                      return (
                        <option value={i} >{i} </option>
                      );
                    })};
                    </select>
                    <span> % </span>
                  </b>
                </p>
                <p>{GST}</p>
              </div>
              <div className='discount'>
                <p>Discount</p>
                <p>
                  <b>
                    <select onChange={(e) => { handleClicks(e.currentTarget.value) }}>
                      {Array.from(Array(100), (e, i) => {
                        return (
                          <option value={i}>{i}</option>
                        )
                      })}
                    </select>
                    <span> % </span>
                  </b>
                </p>
                <p>{discountAmount}</p>
              </div>
              <div className='Net-Amount'>
                <p><b>Payable Amount</b></p>
                <p><b>{Symbol}{totalAmount}  </b></p>
              </div>
            </div>
          </div>
          <div className='notes' id='notes'>
            <p>Note</p>
            <textarea className='note' id='note' onChange={postUserData} value={userdata.note} name="note" placeholder='Write here...'>
            </textarea>
          </div>
          <div className='Submit'>
            <button onClick={goBack}>&nbsp; Back &nbsp;</button>
            <button type='submit'>Submit</button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}
