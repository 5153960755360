import React, { useEffect, useState } from "react";
import axios from "axios";
import AppIcon from '../Payment/images/app-icon.png'
const RazorpayButton = () => {
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [isSubscriptionCreated, setIsSubscriptionCreated] = useState(false);

  useEffect(() => {
    // Load the Razorpay script
    const loadRazorpayScript = () => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.onload = () => {
          resolve(true);
        };
        document.body.appendChild(script);
      });
    };

    loadRazorpayScript();
  }, []);

  const createSubscription = async () => {
    const url = "http://localhost:3001/api/createSubscription"; // Your Next.js API route

    const data = {
      plan_id: "plan_IXJYteatBH94g9",
      total_count: 6,
      quantity: 1,
      // start_at: 1735689600, // Example timestamp
      // expire_by: 1893456000, // Example timestamp
    };

    try {
      // Call the Next.js API to create a subscription
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Get the subscription_id from the response
      const { subscriptionId } = response.data;
      setSubscriptionId(subscriptionId); // Store subscription ID in state
      setIsSubscriptionCreated(true); // Set flag to true
      console.log("Subscription ID:", subscriptionId);
    } catch (error) {
      console.error("Error creating subscription:", error);
    }
  };

  const handlePayment = () => {
    if (!subscriptionId) {
      console.error("Subscription ID not available yet");
      return;
    }

    const options = {
      key: "rzp_test_O5YpNjI1WJIySR", // Replace with your Razorpay Key ID
      subscription_id: subscriptionId, // Use the subscription ID from API response
      name: "BillNama",
      description: "Monthly Test Plan",
      image: {AppIcon}, // Replace with your logo URL
      callback_url: "https://billnama.com/",
      prefill: {
        name: "",
        email: "gaurav.kumar@example.com",
        contact: "+919429022319",
      },
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  return (
    <div>
      <button onClick={createSubscription} disabled={isSubscriptionCreated}>
        {isSubscriptionCreated ? "Subscription Created" : "Create Subscription"}
      </button>
      <button id="rzp-button1" onClick={handlePayment} disabled={!subscriptionId}>
        {subscriptionId ? "Pay" : "Waiting for Subscription..."}
      </button>
    </div>
  );
};

export default RazorpayButton;
