import "./previewsidebar.css";  
import template1 from "./images/template_1.png";
import template2 from "./images/template_2.png";
import template3 from "./images/template_3.png";
import template4 from "./images/template_4.png";
import template5 from "./images/template_5.png";
import template6 from "./images/template_6.png";
import template7 from "./images/template_7.png";
import { Link, useNavigate } from "react-router-dom"; // Use useNavigate instead of useHistory

export default function Preview() {
    const navigate = useNavigate(); // Create a navigate objectl

    const handleClick = (templatePath) => {
        const premiumDate = localStorage.getItem("PremiumDate"); // Get the PremiumDate from local storage
        if (premiumDate) {
            navigate(templatePath); // Navigate to the selected template page
        } else {
            navigate("/subscription"); // Navigate to the subscription page
        }
    };
    return (
        <>
            <div id="preview_select">
                <div>
                    <h2 style={{color :"white", marginBottom:"10px", fontWeight:"normal"}}>Standard</h2>
                    <Link to="/simple-invoice-template">
                        <img
                            src={template1}
                            alt="preview1"
                        />
                    </Link>
                </div>
                <div>
                    <h2 style={{color :"white", marginBottom:"10px", fontWeight:"normal"}}>Cosy</h2>

                    <Link to="/professional-invoice-template">
                        <img
                            src={template2}
                            alt="preview2"
                        />
                    </Link>
                </div>
                <div>
                    <h2 style={{color :"white", marginBottom:"10px", fontWeight:"normal"}}>Professional</h2>
                    <img
                        src={template3}
                        alt="preview3"
                        onClick={() => handleClick("/creative-invoice-template")}
                    />
                </div>
                <div>
                <h2 style={{color :"white", marginBottom:"10px", fontWeight:"normal"}} >Cloud</h2>
                    <img
                        src={template4}
                        alt="preview4"
                        onClick={() => handleClick("/colorful-invoice-template")}
                    />
                </div>
                <div>
                <h2 style={{color :"white", marginBottom:"10px", fontWeight:"normal"}}>Classic</h2>
                    <img
                        src={template5}
                        alt="preview5"
                        onClick={() => handleClick("/standard-invoice-template")}
                    />
                </div>
                <div>
                <h2 style={{color :"white", marginBottom:"10px", fontWeight:"normal"}}>Prime</h2>
                    <img
                        src={template6}
                        alt="preview6"
                        onClick={() => handleClick("/classic-invoice-template")}
                    />
                </div>
                <div>
                <h2 style={{color :"white", marginBottom:"10px", fontWeight:"normal"}}>Modern</h2>
                    <img
                        src={template7}
                        alt="preview7"
                        onClick={() => handleClick("/modern-invoice-template")}
                    />
                </div>
            </div>

            {/* Repeat the same structure for the second preview section if necessary */}
            <div id="preview_selects">
                {/* The same images as above with onClick handlers */}
                <div>
                    <h2>Standard</h2>
                    <Link to="/simple-invoice-template">
                        <img
                            src={template1}
                            alt="preview1"
                        />
                    </Link>
                </div>
                <div>
                    <h2>Cosy</h2>
                    <Link to="/professional-invoice-template">
                        <img
                            src={template2}
                            alt="preview2"
                        />
                    </Link>
                </div>
                <div>
                    <h2>Professional</h2>
                    <img
                        src={template3}
                        alt="preview3"
                        onClick={() => handleClick("/creative-invoice-template")}
                    />
                </div>
                <div>
                    <h2>Cloud</h2>
                    <img
                        src={template4}
                        alt="preview4"
                        onClick={() => handleClick("/colorful-invoice-template")}
                    />
                </div>
                <div>
                    <h2>Classic</h2>
                    <img
                        src={template5}
                        alt="preview5"
                        onClick={() => handleClick("/standard-invoice-template")}
                    />
                </div>
                <div>
                    <h2>Prime</h2>
                    <img
                        src={template6}
                        alt="preview6"
                        onClick={() => handleClick("/classic-invoice-template")}
                    />
                </div>
                <div>
                    <h2>MOdern</h2>
                    <img
                        src={template7}
                        alt="preview7"
                        onClick={() => handleClick("/modern-invoice-template")}
                    />
                </div>
            </div>
        </>
    );
}
