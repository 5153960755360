import { Link, useNavigate } from 'react-router-dom';
import './Subscription.css';
import Logo from "./images/logo.png";
import special from './images/images-removebg-preview (26).png';
import gif from "./images/Dark and White Neon Animated Coming Soon Video (3).gif";
import playstore from "./images/png-transparent-google-play-store-logo-google-play-app-store-android-wallets-text-label-logo-removebg-preview.png";
import appstore from "./images/App-Store-Emblem-removebg-preview.png";
import Header from "../Header/index";
import { Helmet } from 'react-helmet';
import { useEffect, useRef, useState } from 'react';
import Homesreen from "./images/Homescreen.png";
import Background from "./images/background.png";
import Paymentscreen from "./images/paymentscreen.png";

export default function Subscription() {

    const [isFlipped, setIsFlipped] = useState(false);

    const handleFlip = () => {
        setIsFlipped(!isFlipped);
    };


    const [isOpen, setIsOpen] = useState(false);
    const popupRef = useRef(null);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    // Handle outside click
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                closePopup();
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);


    const navigate = useNavigate(); // Initialize the navigate function

    const handleGoBack = () => {
        navigate(-1); // This goes back to the previous page
    };
    return (
        <>

            <Helmet>
                <title> Subscription Plans | BillNama</title>
                <meta
                    name="description"
                    content="Meta Description: Explore our subscription plans tailored to fit your invoicing needs. Choose the perfect plan for your business with BillNama.
                    "
                />
                <link rel="canonical" href="https://billnama.com/subscription" />
            </Helmet>
            <div id="premium_box_popup">
                <div className="premium_box_popup_box">
                    <div className="premium_box_popup_logo">
                        <img src={Logo} />
                    </div>
                    <div className="premium_box_popup_heading">
                        <h2>
                            Plan & Pricing
                        </h2>
                        <p>
                            Explore our variety of plans designed to revolutionize your invoicing experience
                        </p>
                    </div>

                    <div className="premium_box_popup_details">


                        <div className={`premium_box_popup_plan ${isFlipped ? 'flipped' : ''}`}>
                            {/* button */}
                            <div class="btn-container">

                                <label class="switch btn-color-mode-switch" id="flip_button" onClick={handleFlip}>
                                    <input value="1" id="color_mode" name="color_mode" type="checkbox" />
                                    <label class="btn-color-mode-switch-inner" data-off="INR" data-on="USA" for="color_mode" ></label>
                                </label>
                            </div>
                            {/* button */}
                            <div className="premium_box_inner">
                                <div className="premium_box_front">
                                    <h3>Select Your Plan</h3>
                                    {/* Month plan */}
                                    <div className="month_plan">
                                        <div className="month_plan_time">
                                            <label>Monthly</label>
                                        </div>
                                        <div className="month_plan_details">
                                            <h2>Rs.179 / One Month</h2>
                                            <p>Start with <span>3 days</span> trial</p>
                                        </div>
                                        <button >Get Now</button>
                                    </div>
                                    {/* Year plan */}
                                    <div className="month_plan" id="year_plan">
                                        <div className="month_plan_time">
                                            <label>Yearly</label>
                                        </div>
                                        <div className="month_plan_details">
                                            <h2>Rs.1649 / One Year</h2>
                                            <p>Save upto <span> 35%</span></p>
                                        </div>
                                        <button>Get Now</button>
                                    </div>
                                    <Link to="/privacy-statement">
                                        <p className="palan_tearms">Terms and privacy policy</p>
                                    </Link>
                                </div>
                                <div className="premium_box_back" style={{ backfaceVisibility: "hidden !important" }}>
                                    <h3>Select Your Plan</h3>
                                    {/* Month plan */}
                                    <div className="month_plan">
                                        <div className="month_plan_time">
                                            <label>Monthly</label>
                                        </div>
                                        <div className="month_plan_details">
                                            <h2>$3.99 / One Month</h2>
                                            <p>Start with <span>3 days</span> trial</p>
                                        </div>
                                        <button onClick={openPopup}>Get Now</button>
                                    </div>
                                    {/* Year plan */}
                                    <div className="month_plan" id="year_plan">
                                        <div className="month_plan_time">
                                            <label>Yearly</label>
                                        </div>
                                        <div className="month_plan_details">
                                            <h2>$32.99 / One Year</h2>
                                            <p>$1.66/mo.<span> Save 35%</span></p>
                                        </div>
                                        <button onClick={openPopup}>Get Now</button>
                                    </div>
                                    <Link to="/privacy-statement">
                                        <p className="palan_tearms">Terms and privacy policy</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="premium_box_popup_benefits">
                            <table>
                                <tr className="top_row">
                                    <th><i className="fa-solid fa-star"></i>Unlock the Advantages of Our Invoice Solution</th>
                                    <th>Free</th>
                                    <th>Premium</th>
                                </tr>

                                <tr>
                                    <td>No Advertisement</td>
                                    <td className="text_center"><i className="fa-solid fa-xmark"></i></td>
                                    <td className="text_center"><i className="fa-solid fa-check"></i></td>
                                </tr>
                                <tr>
                                    <td>No limitation on business registration</td>
                                    <td className="text_center"><i className="fa-solid fa-xmark"></i></td>
                                    <td className="text_center"><i className="fa-solid fa-check"></i></td>
                                </tr>
                                <tr>
                                    <td>No limitation on invoice</td>
                                    <td className="text_center"><i className="fa-solid fa-xmark"></i></td>
                                    <td className="text_center"><i className="fa-solid fa-check"></i></td>
                                </tr>
                                <tr>
                                    <td>No limitation on invoice preview </td>
                                    <td className="text_center"><i className="fa-solid fa-xmark"></i></td>
                                    <td className="text_center"><i className="fa-solid fa-check"></i></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div className="premiuim_section_skip">
                        <button onClick={handleGoBack} style={{backgroundColor:"transparent", border:"none",outline:"none", textDecoration:"underline", cursor:"pointer"}}>
                            <p>Skip the Professional Invoice!</p>
                        </button>
                    </div>
                </div>
            </div>

            {isOpen && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        overflowY: "auto",
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 9999,
                    }}
                >
                    <div
                        id="USD_popup"
                        ref={popupRef}
                        style={{
                            backgroundColor: 'white',
                            padding: '50px',
                            borderRadius: '8px',
                            width: '80%',
                        }}
                    >
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", position:"relative" }}>
                            <div style={{position:"absolute", right:"-30px", top:"-40px"}}>
                             <b style={{fontSize:"25px", zIndex:"9999", color:"#041737", cursor:"pointer"}} onClick={closePopup}>✖</b>
                             
                            </div>
                            <div style={{ textAlign: "left", width: "60%", paddingLeft: "90px" }}>
                                <h2 style={{ color: "#041737", fontSize: "30px" }}>Download Our App</h2>
                                <p style={{ color: "#B9BEC7", marginTop: "20px", lineHeight: "30px" }}>commerce through prospective manufactured products intrinsicly myocardinata team building methodologies rather than proactive vortals. Credibly deploy accurate infrastructures and 029 synergy. Competently foster multidisciplinary</p>
                                <div style={{ marginTop: "40px", display: "flex", alignItems: "center" }} >
                                    <Link to="https://apps.apple.com/us/app/billnama-invoice-maker-gst/id1444998056" target='_blank'>
                                        <button style={{ backgroundColor: "rgba(34,137,230,0.12)", padding: "13px 18px", border: "none", outline: "none", fontSize: "15px", marginRight: "10px", cursor: "pointer", color: "#2289E6", display: "flex", alignItems: "center" }}>
                                            <i class="fa-brands fa-apple" style={{ marginRight: "10px", fontSize: "25px" }}></i>
                                            <span>Download Now</span>
                                        </button>
                                    </Link>
                                    <Link to="https://play.google.com/store/apps/details?id=com.softnoesis.invoice" target='_blank'>
                                        <button style={{ backgroundColor: "rgba(174,103,209,0.12)", padding: "13px 18px", border: "none", outline: "none", fontSize: "15px", display: "flex", alignItems: "center", color: "#AE67D1", cursor: "pointer", }}>

                                            <i class="fa-brands fa-google-play" style={{ marginRight: "10px", fontSize: "20px" }}></i>
                                            <span>Download Now</span>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div style={{ width: "40%", textAlign: "center" }}>
                                <img src={Paymentscreen} style={{ width: "80%" }} />
                            </div>
                        </div>

                    </div>
                </div>
            )}
        </>
    );
}
