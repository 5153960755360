import React from "react";
import "./privacy.css";
import { Link } from "react-router-dom";
import Headers from "../Header/index";
import Footer from "../Footer/index";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
    return (
        <>
            <Helmet>
                <title>Refund Policy | BillNama</title>
                <meta
                    name="description"
                    content="Understand BillNama's refund policy regarding our subscription plans and services. Learn about our refund process and eligibility criteria."
                />
                <link rel="canonical" href="https://billnama.com/refund-policy"/>
            </Helmet>



            <Headers />
            <div class="context">
                <h1>Refund and cancelling</h1>
            </div>


            <div class="area" >
                <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div >
            <head >
                <meta property="og:url" content="https://www.softnoesis.com/apps/invoice/privacy_policy.php" />
                <meta property="fb:app_id" content="1515597091873107" />
                <meta property="og:title" content="Privacy Policy: BillNama, Bill Generator" />
                <meta property="og:description" content="BillNama, Bill Generator" />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="Privacy Policy: BillNama, Bill Generator" />
                <meta property="og:image" content="https://is2-ssl.mzstatic.com/image/thumb/Purple122/v4/11/00/66/11006672-0614-b999-d907-5c7ca9bdfedf/AppIcon-1x_U007emarketing-0-7-0-85-220.png/246x0w.webp" />
            </head>
            <div id="privacy_police" style={{ padding: '20px', marginLeft: '5%', marginRight: '5%', marginBottom: 150 }} >
                <h3>Refund</h3>
                <p>All Fees paid are non-refundable, except in the event (i) of overpayment. Here, "event (i)" means recurring payment, sometimes an advance payment cut due to some technical reason. So only those payments are refundable. Such refund will take at least 5-7 working days to credit amount to respective bank or credit card account.
                    If Subscriber believes that Invoicemaker has billed Subscriber incorrectly, Subscriber must notify Invoicemaker thereof (in writing) no later than ten (10) days after the date on which Invoicemaker has charged Subscriber, otherwise the amount charged shall be conclusively deemed correct by the parties.
                </p>

                <h3>Cancellation</h3>
                <p>Subscriber can cancel their subscription anytime they want. But here it will only cancel your next recurring payment. However whatever amount has been paid by you so far, for that you will get regular services till your plan expires.
                    Fees for each subscription period will be billed in advance. There will be no grace period. Thereafter, customers may be restricted at any time from the Service, with or without notice, at InvoiceMaker sole discretion.
                    If you have any questions or concerns regarding this Privacy Policy, please contact us as described below. We will make every effort to resolve your concerns.
                </p>
                <p>You can contact us by email at <Link>support@billnama.com</Link></p>
                <p>Appstore: <Link href="https://apps.apple.com/app/invoice-maker-bill-generator/id1444998056" target="_blank" style={{ color: "blue" }}>https://apps.apple.com/app/invoice-maker-bill-generator/id1444998056</Link></p>
                <p>Playstore: <Link href="https://play.google.com/store/apps/details?id=com.softnoesis.invoice" target="_blank" style={{ color: "blue" }}> https://play.google.com/store/apps/details?id=com.softnoesis.invoice</Link></p>
            </div>
            <Footer />
        </>
    );
}