import React from "react";
import  Details from '../Compny-Form/index'
import Header from '../Header';

export default function Detail() {
  return (
    <>   
    <Header/>
    <Details/>
    </>
  );
}