import "./currancy.css";
import Country from "../../COMPONENTS/countries.json";
import Currancy from "../../COMPONENTS/currency.json";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "../Header/index";
import Sidebar from "../Sidebar/Sidebar";
import DashHeader from "../Dashbord/dashheader";
import Invoiceheaderiimage from "../Invoicedata/images/hading_image.png";
import DatanotFound from './images/rMSD8h3gzM.gif';
import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";

export default function Currancys() {

  const handleClick = (value) => {
    localStorage.setItem("symbol", value);
  };

  const handleClicks = (value) => {
    localStorage.setItem("currencyCode", value);
  }

  const handleClickflages = (value) => {
    localStorage.setItem("flag", value);
  }

  const handleClickname = (value) => {
    localStorage.setItem("name", value);
  }

  const [selectedItem, setSelectedItem] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(Currancy.CountryCurrencyResponse);
  const [Taxs, setTax] = useState('');
  let Symbol = localStorage.getItem("symbol");
  let CurrencyCode = localStorage.getItem("currencyCode");

  // Updated TaxType function
  const TaxType = () => {
    let taxType = "TAX"; // Default value
    if (CurrencyCode === "EUR" || CurrencyCode === "CZK" || CurrencyCode === "DKK" || CurrencyCode === "HUF" || CurrencyCode === "PLN" || CurrencyCode === "RON" || CurrencyCode === "SEK" || CurrencyCode === "GBP") {
      taxType = "VAT";
    } else if (CurrencyCode === "INR" || CurrencyCode === "AUD") {
      taxType = "GST";
    }

    setTax(taxType);
    localStorage.setItem("tax", taxType); // Store the tax type in local storage
  }

  useEffect(() => {
    TaxType();
  }, [CurrencyCode]); // Depend on CurrencyCode instead of Taxs

  const handleItemClick = (result) => {
    setSelectedItem(result.name);
    localStorage.setItem('selectedItem', result.name);
  };

  useEffect(() => {
    // Retrieve selected item from local storage on component mount
    const storedSelectedItem = localStorage.getItem('selectedItem');
    const storedCurrencyCode = localStorage.getItem('currencyCode');
    const storedSymbol = localStorage.getItem('symbol');
    const storedName = localStorage.getItem('name');
    const storedFlag = localStorage.getItem('flag');

    // If all stored values are present, update the state accordingly
    if (storedSelectedItem && storedCurrencyCode && storedSymbol && storedName && storedFlag) {
      setSelectedItem(storedSelectedItem);
    } else {
      // If no item is selected in local storage, set the default to Indian Rupee (INR)
      const defaultCurrency = Currancy.CountryCurrencyResponse.find(
        (currency) => currency.currencyCode === "INR"
      );

      if (defaultCurrency) {
        // Set default values in local storage and state
        setSelectedItem(defaultCurrency.name);
        localStorage.setItem('selectedItem', defaultCurrency.name);
        localStorage.setItem('currencyCode', defaultCurrency.currencyCode);
        localStorage.setItem('symbol', defaultCurrency.currencytSymbol);
        localStorage.setItem('name', defaultCurrency.name);

        // Find and set the flag for the default currency
        const indiaFlag = Country.CurrencyResponse.find(
          (res) => res.isoAlpha2 === defaultCurrency.countryCode
        );
        if (indiaFlag) {
          localStorage.setItem('flag', indiaFlag.flag);
        }
      }
    }

    // Update the filtered countries based on the default or stored values
    setFilteredCountries(Currancy.CountryCurrencyResponse);

  }, []); // Empty dependency array ensures this runs only once on mount


  const Currancycoad = localStorage.getItem("currencyCode");
  const Currancysymbol = localStorage.getItem("symbol");
  const Currancyflag = localStorage.getItem("flag");
  const Currancyname = localStorage.getItem("name");

  useEffect(() => {
    // Filter countries based on search query
    const filtered = Currancy.CountryCurrencyResponse.filter(country =>
      country.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      country.currencyCode.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredCountries(filtered);
  }, [searchQuery]);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>
          Make Invoices in Any Currency With Our Multi-Currency Support Feature
        </title>
        <link
          rel="canonical"
          href="https://app.billnama.com/currency"
        />
      </Helmet>
      <div className="dashbord" id="dashbord">
        <div className="space">
        </div>
        <Sidebar />
        <div className="account_details">
          <DashHeader />

          <div className="invoice_info">
            <div className="invoices_details">
              <div className="invoices_head">
                <div>
                  <h2>Currency</h2>
                  <ul>
                    <li id="Serch_customer">
                      <div>
                        <p>Search</p>
                      </div>
                      <div id="serch_currency_lable">
                        <i className="fa-solid fa-magnifying-glass" style={{left:"18px"}}></i>
                        <input type="search" placeholder="Search Currency" onChange={handleSearch} style={{backgroundColor:"white"}}/>
                        <b>
                          {Currancyname} {Currancycoad} {Currancysymbol}
                        </b>
                      </div>
                    </li>
                    <li id="money">
                    </li>
                  </ul>
                </div>
                <div>
                  {/* <img src={Invoiceheaderiimage} alt="Header" /> */}
                </div>
              </div>
            </div>
            <div className="main_section " style={{ position: "relative", padding: "0px" }}
            >
              <div className="bussiness_section_heading" id="curancy_section_heading" style={{ position: "sticky", top: "0px", width: "100%", left: "0px", padding: "15px", backgroundColor: "#3963AD", color: "white", borderRadius: "7px 7px 0px 0px", zIndex: "999", fontSize: "14px" }}>
                <p>Country</p>
                <p>Flag</p>
                <p>Code</p>
                <p>Symbol</p>
              </div>
              <div style={{ padding: "0px 15px" }}>
                {filteredCountries.length > 0 ? filteredCountries.map((result, index) => (
                  <div className="CurrencyResponse" key={index} >
                    <button name={result.currencytSymbol} value={result.currencyCode} onClick={() => {
                      handleClick(result.currencytSymbol);
                      handleClicks(result.currencyCode);
                      handleClickname(result.name);
                      handleItemClick(result);
                      const country = Country.CurrencyResponse.find(res => res.isoAlpha2 === result.countryCode);
                      if (country) {
                        handleClickflages(country.flag); // Pass the base64 flag to the handleClickflages function
                      }
                    }}>
                      <ul id="curancy_iteams">
                        <li className={selectedItem === result.name ? "selected" : ""}>
                          {selectedItem === result.name && <span className="selectcurrancy">✓</span>}
                          <div className="cunry_name">
                            <p>{result.name}</p>
                          </div>
                          <div className="code_symbol">
                            <div className="flag" id="flag">
                              {Country.CurrencyResponse.map((res) => (
                                res.isoAlpha2 === result.countryCode && <img key={res.isoAlpha2} src={"data:image/png;base64," + res.flag} alt="Flag" />
                              ))}
                            </div>
                          </div>
                          <div className="coad">
                            {result.currencyCode}
                          </div>
                          <div className="symbol">
                            {result.currencytSymbol}
                          </div>
                        </li>
                      </ul>
                    </button>
                  </div>
                )) : <div id="datanot_found"><img src={DatanotFound} alt="Data Not Found" /></div>
                }
              </div>

            </div>
            <div className="allrights_recives">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
