
import Subscriptions from './Subscription';

export default function Subscription() {
  return (
    <div>
        <Subscriptions/>
    </div>
  );
}
