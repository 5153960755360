import { Link } from "react-router-dom";
import "./dashbord.css";
import Sidebar from "../Sidebar/Sidebar";
import DashHeader from "./dashheader";
import firebase, { database, get, child, remove, getDatabase } from '../Login/config';
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";
import { Chart as ChartJS, defaults } from "chart.js/auto";
import { Bar, Doughnut, Line, Pie, PolarArea } from "react-chartjs-2";
import Invoice from "./images/invoice.png";
import Business from "./images/company.png";
import Customer from "./images/customer.png";
import Iteams from "./images/sold-items.png"
import Bannerimage from "./images/dashbord_banner.svg";

export default function Dashbord() {
  const UserId = localStorage.getItem("AccessUserId");
  const Username = localStorage.getItem("customer");
  const UserEmail = localStorage.getItem("email");
  const [datas, setData] = useState([]);
  const [company, setCompany] = useState([]);
  const [item, setitems] = useState("");
  const [count, setCount] = useState(0);

  const getbilinvoice = async () => {
    if (UserId) {

      await get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          setData(Object.values(data?.billInvoice || {}));
          // console.log('Object.values(data?.billInvoice', Object.values(data?.billInvoice[0].id));
          setCompany(Object.values(data?.company || {}));
          let totalCount = 0;
          Object.values(data?.billInvoice || {}).forEach((invoice) => {
            totalCount += invoice?.items?.length || 0;
          });
          setCount(totalCount);
        } else {
          console.log("testing data");
        }
      }).catch((error) => {
        console.error(error);
      });
    } else {
      console.log("testing");
    }
  }
  useEffect(() => {
    getbilinvoice();
  }, []);


  const storedPaidCount = localStorage.getItem('paidCount');
  const storedUnpaidCount = localStorage.getItem('unpaidCount');
  return (
    <>
      <Helmet>
        <title>
          Free Invoice Generator Online: Create Invoices Easily | BillNama
        </title>
        {/* <meta
          name="description"
          content="Explore the Invoice mobile app case study by Softnoesis, a testament to our success in mobile development. Discover how we created a user-friendly invoicing solution, enhanced productivity, and streamlined business operations. Click now to dive into this transformative success story!"
        /> */}
        <link
          rel="canonical"
          href="https://app.billnama.com/dashboard"
        />
      </Helmet>
      <div className="dashbord">
        <div className="space"></div>
        <Sidebar />
        <div className="account_details">
          <DashHeader />
          <div className="dashbord_main_section">
            <div className="dashbord_banner">
              <div>
                <p>Invoice Your Customer in Seconds</p>
                <h2>Check Your Daily Invoices,<br/>Items and Customers</h2>
              </div>
              <img src={Bannerimage} />
            </div>
            <div className="subscription_banner">
              <div className="first_chart">
                <Doughnut
                  style={{ width: "320px", height: "320px" }}
                  className="doughnut_chart"
                  data={{
                    labels: [
                      'Paid Invoice',
                      'Unpaid Invoice',
                    ],
                    datasets: [{
                      data: [storedPaidCount, storedUnpaidCount],
                      backgroundColor: [
                        'rgba(39, 94, 254,0.7)',
                        'rgb(255, 99, 132)',
                        // 'rgb(255, 205, 86)'
                      ],
                    }]
                  }}
                />
              </div>
              <div className="second_chart">
                <Line
                  style={{ width: "320px", height: "320px" , marginTop:"40px" }}
                  className="doughnut_chart"
                  data={{
                    labels: [
                      'Paid Invoice',
                      'Unpaid Invoice',
                    ],
                    datasets: [{
                      data: [storedPaidCount, storedUnpaidCount],
                      backgroundColor: [
                        'rgba(39, 94, 254,0.7)',
                        'rgb(255, 99, 132)',
                        // 'rgb(255, 205, 86)'
                      ],
                    }]
                  }}
                />
              </div>
            </div>
          </div>
          <div className="details">
            <Link to="/invoices">
              <div className="total_invoice">
                <div className="info">
                  <div>
                    <img src={Invoice} />
                  </div>
                  <div>
                    <p>{datas.length}</p>
                    <h2>Total Invoice</h2>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/listed-business">
              <div className="total_invoice" id="total_business">
                <div className="info">
                  <div>
                    <img src={Business} />
                  </div>
                  <div>
                    <p>{company.length}</p>
                    <h2>Total Business</h2>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/customers">
              <div className="total_invoice" id="total_customer">
                <div className="info">
                  <div>
                    <img src={Customer} />
                  </div>
                  <div>
                    <p>{datas.length}</p>
                    <h2>Total Customers</h2>
                  </div>
                </div>
              </div>
            </Link>
            <Link to="/sold-products">
              <div className="total_invoice" id="total_solditeam">
                <div className="info">
                  <div>
                    <img src={Iteams} />
                  </div>
                  <div>
                    <p>{count}</p>
                    <h2>Total Sold Items</h2>
                  </div>
                </div>
              </div>
            </Link>
            <div id="dashboard_footer">
              <Footer />
            </div>
          </div>
          <div className="bar_charts">
            {/* <div className="first_chart">
              <Line
                className="line_chart"
                data={{
                  labels: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
                  datasets: [{
                    label: 'Hide Data',
                    data: [59, 34, 84, 94, 65, 76, 69, 51, 35, 83, 95, 54],
                    backgroundColor: [
                      // 'rgba(255, 99, 132, 0.2)',
                      // 'rgba(255, 159, 64, 0.2)',
                      // 'rgba(255, 205, 86, 0.2)',
                      // 'rgba(75, 192, 192, 0.2)',
                      // 'rgba(54, 162, 235, 0.2)',
                      // 'rgba(153, 102, 255, 0.2)',
                      // 'rgba(201, 203, 207, 0.2)'
                      'rgba(39, 94, 254,0.2)'
                    ],
                    fill: true,
                    borderColor: 'rgba(39, 94, 254)',
                    // tension: 0.1,
                    borderWidth: 1
                  }]
                }}
              />
            </div>
            <div className="first_chart">
              <Doughnut
                style={{ width: "320px", height: "320px" }}
                className="doughnut_chart"
                data={{
                  labels: [
                    'Paid Invoice',
                    'Unpaid Invoice',
                  ],
                  datasets: [{
                    data: [storedPaidCount,storedUnpaidCount ],
                    backgroundColor: [
                      'rgba(39, 94, 254,0.7)',
                      'rgb(255, 99, 132)',
                      // 'rgb(255, 205, 86)'
                    ],
                  }]
                }}
              />
            </div> */}
          </div>

          {/* 
          <div className="bar_charts_new_section">
                    <div className="bar_charts_new_section_first_chart">

                    </div>
                    <div className="bar_charts_new_section_second_chart">

                    </div>
          </div> */}


        </div>

      </div>

    </>
  )
}
