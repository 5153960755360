import { Link } from 'react-router-dom';
import './Login.css';
import { auth, provider, getDatabase, ref, set } from "./config";
import { signInWithPopup, FacebookAuthProvider, getAuth, reauthenticateWithPopup, OAuthProvider } from 'firebase/auth';
import { useEffect, useState } from 'react';
import Homes from "../Home/index";
import Swal from 'sweetalert2';
import Image from "./images/vectore.png";
import Logo from './images/logo.png';
import firebase from "./config";
import Dashboard from '../Dashbord/index';
import { Helmet } from 'react-helmet';

export default function Sign() {

  const venderId = Math.floor(Math.random() * 9000000000000) + 1;
  const [value, setvalue] = useState('');


  // Start session data code....

  const [user, setUser] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
        // Store session data in Realtime Database

      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const storeSessionData = async (user) => {

    // console.log("_tokenResponse",user);
    // const federatedId  = _tokenResponse.federatedId;
    // console.log('federatedId', federatedId)
    const userId = user.providerData[0].uid;
    // console.log("userforsession",user);
    const db = getDatabase();
    const sessionRef = ref(db, `invoiceGenerator/users/${userId}/session/${venderId}`);
    // console.log("sesion",sessionRef);

    const browserName = detectBrowser(navigator.userAgent);
    // Function to detect browser name
    const deviceName = detectDeviceName(navigator.userAgent); // Get user agent for device name
    const createdDate = Date.now(); // Get current time
    const deviceType = "Website";
    const deviceModel = detectDeviceModel(navigator.userAgent);
    try {
      await set(sessionRef, {
        browserName,
        deviceName,
        deviceType,
        createdDate,
        deviceModel,
        venderId

      });
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  };

  const detectBrowser = (userAgent) => {
    // const userAgent = navigator.userAgent;
    // let browserName = "Unknown";

    if (userAgent.includes("Firefox")) {
      return "Mozilla Firefox";
    } else if (userAgent.includes("SamsungBrowser")) {
      return "Samsung Internet";
    } else if (userAgent.includes("Opera") && userAgent.includes("OPR")) {
      return "Opera";
    } else if (userAgent.includes("Edge")) {
      return "Microsoft Edge (Legacy)";
    } else if (userAgent.includes("Edg")) {
      return "Microsoft Edge (Chromium)";
    } else if (userAgent.includes("Chrome")) {
      return "Google Chrome or Chromium";
    } else if (userAgent.includes("Safari")) {
      return "Apple Safari";
    } else {
      return "unknown";
    }

    // return browserName;
  };

  const detectDeviceModel = (userAgent) => {
    // Regular expressions to match common device models in user agent strings
    const iphoneRegex = /iPhone\s([\w\s]+)/; // Updated regex
    const androidRegex = /Android\s([\w\s.]+)/; // Updated regex

    let deviceModel = "Unknown";

    // Attempt to match device model based on user agent
    if (iphoneRegex.test(userAgent)) {
      const match = userAgent.match(iphoneRegex);
      console.log("iPhone match:", match); // Log the matched values
      deviceModel = match ? match[1] : "Unknown";
    } else if (androidRegex.test(userAgent)) {
      const match = userAgent.match(androidRegex);
      console.log("Android match:", match); // Log the matched values
      deviceModel = match ? match[1] : "Unknown";
    }

    return deviceModel;
  };

  const detectDeviceName = (userAgent) => {
    // Check if the userAgent contains 'iPhone' or 'Android' to determine the device name
    if (userAgent.includes('iPhone')) {
      return 'iPhone';
    } else if (userAgent.includes('Android')) {
      return 'Android';
    } else if (userAgent.includes('Chrome')) {
      return 'Website';
    } else if (userAgent.includes("Firefox")) {
      return "Mozilla Firefox";
    } else if (userAgent.includes("SamsungBrowser")) {
      return "Samsung Internet";
    } else if (userAgent.includes("Opera") && userAgent.includes("OPR")) {
      return "Opera";
    } else if (userAgent.includes("Edge")) {
      return "Microsoft Edge (Legacy)";
    } else if (userAgent.includes("Edg")) {
      return "Microsoft Edge (Chromium)";
    } else if (userAgent.includes("Chrome")) {
      return "Google Chrome or Chromium";
    } else if (userAgent.includes("Safari")) {
      return "Apple Safari";
    } else {
      return 'Unknown'; // If the device name cannot be determined
    }
  };
  // End session data code....




  const handleClick = () => {
    signInWithPopup(auth, provider).then((data) => {
      console.log("testdata", data);
      // console.log('first', data._tokenResponse.federatedId)
      // const federatedId  = data._tokenResponse.federatedId;
      const userId = data.user.providerData[0].uid;
      setvalue(data.user.email);
      localStorage.setItem("email", data.user.email);
      localStorage.setItem("UserName", data.user.displayName);

      setvalue(data.user.accessToken);
      localStorage.setItem("access", data.user.accessToken);
      localStorage.setItem("AccessUserId", userId);
      // Store session data after successful authentication
      storeSessionData(data.user, data);


      // curency set 
      localStorage.setItem("tax", "GST");
      localStorage.setItem("currencyCode", "INR");
      localStorage.setItem("symbol", "₹");
      localStorage.setItem("selectedItem", "India");

      window.location = "/dashboard";

    }).catch((error) => {
      console.log(error.message);
    });
  }

  useEffect(() => {
    setvalue(localStorage.getItem("email"))
  });



  // // Face book login strat
  const signInWitheFacebook = () => {
    const provider = new FacebookAuthProvider();
    signInWithPopup(auth, provider)
      .then((data) => {
        console.log(data);
        const federatedId = data._tokenResponse.federatedId;
        const userId = federatedId.substring(federatedId.lastIndexOf('/') + 1);
        setvalue(data.user.email);
        localStorage.setItem("email", data.user.email);
        localStorage.setItem("UserName", data.user.displayName);

        setvalue(data.user.accessToken);
        localStorage.setItem("access", data.user.accessToken);
        localStorage.setItem("AccessUserId", userId);
        storeSessionData(data.user);
        window.location = "/dashboard";
      })
      .catch((err) => {
        console.log(err.message)
      })
  }
  // // Face book login End

  
  // apple login in strat
  const SignInWithApple = () => {
    const auth = getAuth();
    const provider = new OAuthProvider('apple.com');
    signInWithPopup(auth, provider)
      .then((data) => {
        console.log(data);
        // console.log("apple user",data.user);
        const federatedId = data._tokenResponse.federatedId;
        const userId = federatedId.substring(federatedId.lastIndexOf('/') + 1);
        const cleanedUserId = userId.replace(/[^\w\s]/gi, '');
        setvalue(data.user.email);
        localStorage.setItem("email", data.user.email);
        localStorage.setItem("UserName", data.user.displayName);


        setvalue(data.user.accessToken);
        localStorage.setItem("access", data.user.accessToken);
        localStorage.setItem("AccessUserId", cleanedUserId);
        // Store session data after successful authentication
        storeSessionData(data.user);

        window.location = "/dashboard";
      })
      .catch((error) => {
        console.log("false");
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        // const email = error.customData.email;
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error);

        // ...
      });

  }
  // apple login in End






  return (
    <>
      <Helmet>
        <title>Free Invoice Maker Tool for your Businesses - BillNama</title>
        {/* <meta
          name="description"
          content="Effortlessly manage your business finances with our BillNama. Generate bills and simplify your financial processes with ease. Streamline your business operations today."
        /> */}
        <link rel="canonical" href="https://login" />
      </Helmet>
      {value ? <Dashboard /> :
      

      
        <div className='login-form' >
            <div className='login-form_section'>
          <div className='login_form_details'>
              <img src={Logo} /> 
              
              <h2>
                Welcome..
              </h2>
              <p>Please login to your accout.</p>
            <button className='Googel' onClick={handleClick}>
               <i className="fa-brands fa-google"></i>Sign in with Google
              </button>
            <button className='Face-book' onClick={signInWitheFacebook}>
                <i className="fa-brands fa-facebook-f"></i>   Sign in with Facebook
              </button>
            <button className='ios' onClick={SignInWithApple}>
               
                  <i className="fa-brands fa-apple"></i>Sign in with apple
               
              </button>
              <Link to="/dashboard">
          <p className='skip'>Continue as Guest</p>
          </Link>
          </div>
          <div className='login_form_img'>
              <img src={Image}/>
          </div>
          </div>
          
        </div>

      }

    </>
  );
}


