import './Cusform.css';
import React, { useEffect, useState } from 'react';
import Footer from '../Footer/index';
import firebase, { child, database, get, storage, useFirebase, databaseurl } from '../Login/config';
// import { ref } from "firebase/database";
// import ConfirmBox from "react-dialog-confirm";
// import { Textbox, Radiobox, Checkbox, Select, Textarea } from 'react-inputs-validation';
import Header from '../Header/index';
import 'react-inputs-validation/lib/react-inputs-validation.min.css';
import { Navigate, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import Country from "../../COMPONENTS/countries.json";
import Currancy from "../../COMPONENTS/currency.json";
import { Helmet } from 'react-helmet';
import zIndex from '@mui/material/styles/zIndex';
// import Currancys from "../Currancy/currancy.js"


export default function Rate() {

  // Get Company Data Code Start.
  // let id= "4";

  const CompanyData = JSON.parse(localStorage.getItem("formData"));
  // console.log(CompanyData.companyName, "CompanyData");

  const UserId = localStorage.getItem("AccessUserId");
  const companyName = localStorage.getItem("companyName");
  const selectedCompanyId = localStorage.getItem("selectedCompanyId");
  const [companydata, setCompany] = useState();
  let bildata = [];
  const [CompanyId, setCompanyId] = useState("");
  const [datas, setData] = useState();
  const [testcomid, setTestComId] = useState();
  const getcompany = () => {
    get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
      if (snapshot.exists()) {
        bildata = snapshot.val().billInvoice;
        const data = snapshot.val();
        const companies = Object.entries(data.company);
        const companyIds = companies.map(([key, value]) => value.companyId);
        setTestComId(companyIds);
        setCompany(companies);
        // setCompanyId(companyIds);
        setData(Object.entries(data.billInvoice));

        for (let index = 0; index < companies.length; index++) {
          setCompanyId(companies[index][1].companyId)
        }

      }
    }).catch((error) => {
      console.error(error);
    });
  };
  useEffect(() => {
    getcompany();
  }, [CompanyId]);

  // Get Company Data Code End.


  if (datas && datas.length > 0 && selectedCompanyId) {
    let lastMatchingId = null; // Initialize a variable to store the last matching ID or null
    let matchCount = 0;        // Initialize a counter to track the number of matches
  
    // Loop through the datas array
    datas.forEach((dataArray) => {
      if (dataArray && dataArray[1] && dataArray[1].companyId === selectedCompanyId) {
        if (dataArray[1].id) {
          lastMatchingId = dataArray[1].id; // Store the last matching ID
          matchCount++;                     // Increment the match count
        }
      }
      else{
        // lastMatchingId = 0;
      }
    });
  
    if (lastMatchingId) {
      // console.log("Last Matching ID:", lastMatchingId);
      localStorage.setItem("id", lastMatchingId || 0);
    } else {
      // Set 0 in localStorage if no matching ID is found
      // console.log("No matching ID found. Setting ID to 0.");
      localStorage.setItem("id", 0);
    }
  
    // console.log(`Total matching entries: ${matchCount}`);
  } else {
    // console.error("Datas array is empty/undefined, or selectedCompanyId is missing");
    localStorage.setItem("id", 0);
  }


  const localId = parseInt(localStorage.getItem("id")); // Retrieve and parse the value from localStorage
  let id = String(localId + 1); // Increment the value by 1

  

  const UpdateInvoiceId = localStorage.getItem("Update_Invoice_Id");
  // Apply tax code start

  const [Taxs, setTax] = useState('');
  let Symbol = localStorage.getItem("symbol");
  let CurrencyCode = localStorage.getItem("currencyCode");
  const TaxType = () => {
    let taxType = "TAX"; // Default value
    if (CurrencyCode === "EUR" || CurrencyCode === "CZK" || CurrencyCode === "DKK" || CurrencyCode === "HUF" || CurrencyCode === "PLN" || CurrencyCode === "RON" || CurrencyCode === "SEK" || CurrencyCode === "GBP") {
      taxType = "VAT";
    } else if (CurrencyCode === "INR" || CurrencyCode === "AUD") {
      taxType = "GST";
    }

    setTax(taxType);
    localStorage.setItem("tax", taxType); // Store the tax type in local storage
  }
  useEffect(() => {
    TaxType();
  }, [CurrencyCode]); // Depend on CurrencyCode instead of Taxs
  const [taxvalue, settaxvalue] = useState("");
  const handleClick = (value) => {
    settaxvalue(value);
  };
  const [discount, setdiscount] = useState("");
  const handleClicks = (value) => {
    setdiscount(value);
  };

  // Apply tax code end

  const UserID = localStorage.getItem("AccessUserId");
  const venderId = Math.floor(Math.random() * 9000000000000) + 1;
  // const companyId = CompanyId != "undefined" ? CompanyId : venderId + 1500920;
  const companyId = String(selectedCompanyId);
  // console.log('companyId', companyId)
  // const companyIdInt = parseInt(companyIdString);
  // console.log('companyId', companyId)

  const invoiceId = Date.now().toString();
  const isigst = false;
  const date = new Date();
  const timestampSeconds = Math.floor(date.getTime() / 1000); // Convert milliseconds to seconds
  // console.log(timestamp);
  // console.log('date', date)
  const Day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const Year = date.getFullYear();
  // const creationDate = Day + "  " + month + ", " + Year;
  const creationDate = String(timestampSeconds);
  const updateDate = String(timestampSeconds);
  const billinvoicedate = String(timestampSeconds);
  const Itemcreactiondate = String(timestampSeconds);
  const Itemupdatedate = String(timestampSeconds);

  const customInvoiceId = String(localId + 1);
  const [userdata, SetUserdata] = useState({
    id: customInvoiceId,
    customer: "",
    email: "",
    cus_invoice_number: "",
    address: "",
    dueDate: Math.floor(new Date().getTime() / 1000).toString(), // Store the timestamp in seconds
    cus_city: "",
    customerGSTNo: "",
    phone: "",
    note: "",
  });

  // add iteam Fun start
  const [items, setitems] = useState([{ billId: customInvoiceId, companyId: String(companyId), creationDate: Itemcreactiondate, updateDate: Itemupdatedate, itemId: String(venderId), name: "", subtitle: "", quantity: "", price: "", amount: "" }]);


  const add_iteam = () => {
    setitems([...items, {
      billId: customInvoiceId, companyId: String(companyId), itemId: String(venderId), name: "", subtitle: "", quantity: "", price: "", amount: "", creationDate: Itemcreactiondate, updateDate: Itemupdatedate
    }]);
  }
  const remove_iteam = (index) => {
    const list = [...items];
    list.splice(index, 1);
    setitems([...list]);
  }
  let total = 0;
  let quantityTotal = 0;
  // let id;
  // const [id, setId] = useState();



  // Validation code start....
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
    console.log('items', items)
    // Validate name
    if (!userdata.customer) {
      newErrors.customer = 'Customer Name is required';
      isValid = false;
    }

    if (!items[0].name) {
      newErrors.name = 'Item Name is required';
      isValid = false;
    }

    if (!items[0].quantity) {
      newErrors.quantity = 'one quantity  is required';
      isValid = false;
    }
    if (!items[0].price) {
      newErrors.price = 'Rate is required';
      isValid = false;
    }


    setErrors(newErrors);
    return isValid;
  };
  // Validation code end....


  const postUserData = (e) => {
    // SetUserdata({ ...userdata, [name]: value });
    SetUserdata({ ...userdata, [e.target.name]: e.target.value });
  }

  const TotalAmount = localStorage.getItem("total");
  const totalQuantities = localStorage.getItem("quantityTotal")
  const Growprice = parseInt(TotalAmount);
  const discountAmount = String((Growprice * discount / 100).toFixed(2));
  const TaxAmount = (Growprice - discountAmount);
  const GST = (TaxAmount * taxvalue / 100);
  const gstAmount = String(GST);
  const gstPercetage = String(taxvalue);
  const discountPercetage = String(discount || 0.00);
  localStorage.setItem("DiscountAmounts", discountAmount);
  const totalAmount = String((TaxAmount + GST).toFixed(2));
  localStorage.setItem("totalAmount", totalAmount);
  // tax and discount code end

  useEffect(() => {

  }, [userdata]);

  const submitedata = async (event) => {
    event.preventDefault();

    localStorage.setItem("ProductData", JSON.stringify(items));
    localStorage.setItem("Growprice", parseInt(total).toFixed(2));
    localStorage.setItem("Gst", GST);

    const { customer, email, cus_invoice_number, dueDate, address, customerGSTNo, phone, note } = userdata;

    // Ensure form validation is passed before submitting the data
    if (validateForm()) {
      try {
        const res = await fetch(`${databaseurl}/invoiceGenerator/users/${UserID}/billInvoice.json`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id, customer, email, address, dueDate, customerGSTNo, phone, paidFlagKey, isigst,
            companyId, invoiceId, customInvoiceId, gstAmount, gstPercetage, discountAmount, discountPercetage, totalAmount,
            totalQuantity: String(totalQuantities), creationDate, updateDate, date: billinvoicedate, note, items,
          }),
        });

        // Check if the response is successful
        if (res.ok) {
          // Log to ensure the invoiceId exists
          console.log("Invoice ID: ", invoiceId);

          // Store the invoice ID in localStorage
          localStorage.setItem("createdInvoiceId", invoiceId);

          // Log the success
          console.log("Invoice ID stored in localStorage:", localStorage.getItem("createdInvoiceId"));

          // Redirect to /myinvoice
          window.location.href = "/myinvoice";
        } else {
          // Log an error if the response fails
          console.error("Failed to store the invoice data, response status:", res.status);
        }
      } catch (error) {
        // Catch any errors in the fetch process
        console.error("Error during the fetch request:", error);
      }
    } else {
      console.log("Form validation failed. Data not stored.");
    }
  };

  //  Set browsername in firebase and localstorage code start

  function getBrowserName(userAgent) {
    // The order matters here, and this may report false positives for unlisted browsers.
    if (userAgent.includes("Firefox")) {
      return "Mozilla Firefox";
    } else if (userAgent.includes("SamsungBrowser")) {
      return "Samsung Internet";
    } else if (userAgent.includes("Opera") && userAgent.includes("OPR")) {
      return "Opera";
    } else if (userAgent.includes("Edge")) {
      return "Microsoft Edge (Legacy)";
    } else if (userAgent.includes("Edg")) {
      return "Microsoft Edge (Chromium)";
    } else if (userAgent.includes("Chrome")) {
      return "Google Chrome or Chromium";
    } else if (userAgent.includes("Safari")) {
      return "Apple Safari";
    } else {
      return "unknown";
    }
  }
  const browserName = getBrowserName(navigator.userAgent);

  //  Set browsername in firebase and localstorage code end

  let navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  }

  // Function to handle select change
  const [selectedOption, setSelectedOption] = useState('');
  useEffect(() => {
    // Set the first company's ID as the selected option if companydata is available
    if (companydata && companydata.length > 0) {
      setSelectedOption(companydata[0][1].companyId);
    }
  }, [companydata]);

  useEffect(() => {
    // Check if selectedCompanyId is already in localStorage
    if (!selectedCompanyId && companydata && companydata.length > 0) {
      const firstCompanyId = companydata[0][1].companyId;
      const firstCompanyName = companydata[0][1].companyName;

      // Set the first company as the default selected option
      setSelectedOption(firstCompanyId);
      localStorage.setItem('selectedCompanyId', firstCompanyId);
      localStorage.setItem('company_id', firstCompanyId);

      console.log(`Selected first company: ${firstCompanyName}`);
    } else if (selectedCompanyId) {
      // Set the state to the value stored in localStorage
      setSelectedOption(selectedCompanyId);
    }
  }, [companydata, selectedCompanyId]);

  const handleSelectChange = (event) => {
    const selectedItemId = event.target.value;
    // Update state
    setSelectedOption(selectedItemId);
    // Update localStorage
    localStorage.setItem('selectedCompanyId', selectedItemId);
    localStorage.setItem('company_id', selectedItemId);
  };

  // paid unpaid start

  // Initialize state variable for checkbox value

  const [isPaid, setIsPaid] = useState(false);

  const handleToggleChange = () => {
    const newValue = !isPaid;
    setIsPaid(newValue);
    // paidFlagKey
    // console.log(newValue);
  };
  const paidFlagKey = isPaid;
  const Customeplaceholder = "Enter" + Taxs;
  // paid unpaid end


  // curency

  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCountries, setFilteredCountries] = useState(Country.CurrencyResponse);
  const handleItemClick = (result) => {
    setSelectedItem(result.name);
    console.log('selectedItem', selectedItem);
    localStorage.setItem('selectedItem', result.name);
  };

  useEffect(() => {
    // Check if currencyCode is not in localStorage and set default values to INR
    if (!localStorage.getItem('currencyCode')) {
      localStorage.setItem('currencyCode', 'INR');
      localStorage.setItem('symbol', '₹');
      localStorage.setItem('selectedItem', 'India'); // Adjust 'India' based on your data
    }
  }, []);

  // curency
  return (
    <>

      <Helmet>
        <title>
          Generate invoices easily & quickly with BillNama invoice maker tool
        </title>
        {/* <meta
          name="description"
          content="Explore the Invoice mobile app case study by Softnoesis, a testament to our success in mobile development. Discover how we created a user-friendly invoicing solution, enhanced productivity, and streamlined business operations. Click now to dive into this transformative success story!"
        /> */}
        <link
          rel="canonical"
          href="https://app.billnama.com/generate-invoice"
        />
      </Helmet>
      <Header />
      <div className='Form'>
        <form onSubmit={submitedata}>
          <div className='compnay_details' style={{
            position: "fixed", top: "0px", left: "50%", transform: "translateX(-50%)", zIndex: "9999", paddingTop: "5px"
          }}>
            {UserId &&
              <div className='business_dropdown'>

                {/* Company details code start  */}
                {companydata
                  ? companydata.map((company_data, i) => {
                    return (
                      <div className="compny_details_logo" key={i}>
                        {selectedOption === company_data[1].companyId && (
                          <img src={company_data[1].companyPhoto} alt="Company logo" />
                        )}
                      </div>
                    );
                  })
                  : ""}
                <select onChange={handleSelectChange} value={selectedOption}>
                  <option disabled>Select Your Business</option>
                  {companydata
                    ? companydata.map((company_data, i) => {
                      return (
                        <option key={i} value={company_data[1].companyId}>
                          {company_data[1].companyName}
                        </option>
                      );
                    })
                    : ""}
                </select>
                <i className="fa-solid fa-angle-down"></i>
              </div>
              || companyName &&
              <div className='bussiness' >
                <div>
                  <div className="Compny">
                    <div className='Compny_info'>
                      <div className="compny_left">
                        <div className='compny_details_logo'>
                          {/* <img src={Logo} /> */}
                        </div>
                        <div className='compny_info_details'>
                          <h3>{companyName}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              || "No Records found"}
          </div>
          <div className='Com_cus'>
            {/* Customer Details */}
            <div className='customer-info'>
              <div className='details'>
                <div className='cus-name'>
                  <div className='seven-input' id="date">
                    <h3><i className="fa-solid fa-address-book"></i> Customer Details</h3>
                    <div>
                      <label>Due Date</label>
                      <input type='date' id='dueDate' onChange={postUserData} value={userdata.dueDate} name="dueDate" style={{ backgroundColor: "white", cursor: "pointer" }} />
                    </div>
                  </div>
                </div>
                {/* Cus name & Gst number  start*/}
                <div className='Cusname_gst'>
                  <div className='eight-input'>
                    <div> <label>Customer name</label></div>
                    <input type='text' id='customer' onChange={postUserData} value={userdata.customer} name="customer" placeholder='Enter name' />
                    {errors.customer && <span >{"⚠" + " " + errors.customer}</span>}

                  </div>
                  <div className='nine-input'>
                    <div> <label>{Taxs}</label></div>
                    <input min="0" id='customerGSTNo' onChange={postUserData} value={userdata.customerGSTNo} name="customerGSTNo" placeholder={Customeplaceholder} />
                  </div>
                </div>
                {/* Cus name & Gst number  End*/}
                {/* Cus Email & Mobile number  start*/}
                <div className='cusemail_number'>
                  <div className='ten-input'>
                    <div><label htmlFor="email">Email</label></div>
                    <input type='text' id='email' name="email" onChange={postUserData} value={userdata.email} placeholder='name@gmail.com' />
                  </div>
                  <div className='eleven-input'>
                    <div> <label htmlFor="phone">Phone number</label></div>
                    <input type='number' id='phone' name="phone" onChange={postUserData} value={userdata.phone
                    } placeholder='+91 XXX XXX XXX XX' />

                  </div>
                </div>
                {/* Cus Email & Mobile number  End*/}
                {/* Cus Address  start*/}
                <div className='cus-add'>
                  <div><label>Address</label></div>
                  <div className='Thirteen-input'>
                    <textarea className='address' id='address' onChange={postUserData} value={userdata.address} name="address" placeholder='Enter Address' />
                  </div>
                  {/* content set */}
                </div>
                {/* Cus Address  End*/}
              </div>
            </div>
          </div>
          <div className='Bill-item' id='Bill-item'>
            <div className='items_heading'>
              <div className='items_heding'>
                <span>Item Name</span>
              </div>
              <div className='items_fulldetails'>
                <p> Quantity</p>
                {/* Quantity / Hours */}
                <p>Rate</p>
                <p>AMOUNT</p>
              </div>
            </div>
            {items.map((item, index) => {
              total = total + Number(item.amount);
              localStorage.setItem("total", total);
              quantityTotal = quantityTotal + Number(item.quantity);
              localStorage.setItem("quantityTotal", quantityTotal);
              return (
                <>
                  <div className='Add-item' id='add-iteam'>

                    <div className='Item'>
                      <input value={item.name}
                        onChange={(elemet) => {
                          const list = [...items];
                          list[index].name = elemet.target.value;
                          setitems(list);
                          // setitems({ ...list, [elemet.target.name]: elemet.target.value });
                        }}
                        name="item"
                        placeholder='Enter Item Name'
                      />
                      {errors.name && <span >{"⚠" + " " + errors.name}</span>}
                    </div>
                    <div className='Quantity'>
                      <input type='number' min="0" value={item.quantity}
                        onChange={(elemet) => {
                          const list = [...items];
                          list[index].quantity = elemet.target.value;
                          setitems(list);
                        }}
                        onWheel={event => event.currentTarget.blur()}
                        name='qty'
                        placeholder='0' />
                      {errors.quantity && <span >{"⚠" + " " + errors.quantity}</span>}
                      {/* <label>Quantity/Hours*</label> */}
                    </div>
                    <div className='Price'>
                      <input type='number' min="0" value={item.price}
                        onChange={(elemet) => {
                          const list = [...items];
                          list[index].price = elemet.target.value;
                          list[index].amount = (list[index].quantity * Number(elemet.target.value)).toString();
                          setitems(list);
                        }}
                        onWheel={event => event.currentTarget.blur()}
                        name='price'
                        placeholder='00.00' />
                      {errors.price && <span >{"⚠" + " " + errors.price}</span>}
                      {/* <label>Rate*</label> */}
                    </div>
                    <div className='Grows-price' id="amount">
                      {/* <p >Amount</p> */}
                      <span>{Symbol}{item.price * item.quantity || item.quantity * item.price}</span>
                    </div>

                    {index == 0 ? (<div className='remove' style={{ visibility: "hidden" }}>
                      <i className="fa-solid fa-xmark" onClick={() => remove_iteam(index)}></i>
                    </div>) : (<div className='remove'>
                      <i className="fa-solid fa-xmark" onClick={() => remove_iteam(index)}></i>
                    </div>)}
                  </div>

                  <div>
                    <div className='Description' id='Description'>
                      <input value={item.subtitle}
                        onChange={(elemet) => {
                          const list = [...items];
                          list[index].subtitle = elemet.target.value;
                          setitems(list);
                        }}
                        placeholder='Enter Item Details' />
                      {/* <label>Details*</label> */}
                    </div>
                  </div>
                </>
              );
            })}
            <div className='new-add' id='new-add'>
              {/* <button type="button" onClick={add_iteam}>
                <span className='pluse'>+</span>
                <span>Add a New item</span>
              </button> */}
              <button type="button" class="addbutton" onClick={add_iteam}>
                <span class="button__text">Add Item</span>
                <span class="button__icon"><svg xmlns="http://www.w3.org/2000/svg" width="24" viewBox="0 0 24 24" stroke-width="2" stroke-linejoin="round" stroke-linecap="round" stroke="currentColor" height="24" fill="none" class="svg"><line y2="19" y1="5" x2="12" x1="12"></line><line y2="12" y1="12" x2="19" x1="5"></line></svg></span>
              </button>
            </div>
          </div>


          <div className='Total-price'>
            <div className='notes' id='notes'>
              <p>Note</p>
              <textarea className='note' id='note' onChange={postUserData} value={userdata.note} name="note" placeholder='Write here...' style={{ border: "1px solid #dfdfdf" }}>
              </textarea>
            </div>
            <div className='Total-price-details'>
              <div className='curency' id="customer_curency">
                <select
                  id="custmer_currency_value"
                  onChange={(e) => {
                    localStorage.setItem("currencyCode", (e.target.value.split(',')[0]));
                    localStorage.setItem("symbol", (e.target.value.split(',')[1]));
                    localStorage.setItem("selectedItem", (e.target.value.split(',')[2]));
                    localStorage.setItem("name", (e.target.value.split(',')[2]));

                  }}
                >
                  {filteredCountries.length > 0 ? filteredCountries.map((result, index) => (
                    <option
                      selected={localStorage.getItem('currencyCode') === result.currency.code}
                      value={`${result.currency.code},${result.currency.symbol},${result.name}`}
                      className="CurrencyResponse"
                      key={index}
                    >
                      {result.currency.code} ({result.currency.symbol})
                    </option>
                  )) : (
                    <div id="datanot_found">
                      <h2>Data not found</h2>
                    </div>
                  )}
                </select>
              </div>
              <div className='Price'>
                <p>Gross Amount</p>
                <p>{Symbol} {total} </p>
              </div>
              <div className='gst'>
                <p>{Taxs} </p>
                <p>
                  <b>
                    <select onChange={(e) => { handleClick(e.currentTarget.value) }}>{Array.from(Array(100), (e, i) => {
                      return (
                        <option value={i} >{i} </option>
                      );
                    })};
                    </select>
                    <span> % </span>
                  </b>
                </p>
                <p>{GST}</p>
              </div>
              <div className='discount'>
                <p>Discount</p>
                <p>
                  <b>
                    <select onChange={(e) => { handleClicks(e.currentTarget.value) }}>
                      {Array.from(Array(100), (e, i) => {
                        return (
                          <option value={i}>{i}</option>
                        )
                      })}
                    </select>
                    <span> % </span>
                  </b>
                </p>
                <p>{discountAmount}</p>
              </div>
              <div className='Net-Amount'>
                <p><b>Payable Amount</b></p>
                <p><b>{Symbol}{totalAmount}  </b></p>
              </div>
              <div className='paid_unpaid'>
                <div>
                  <label class="switch">
                    <input
                      id="checkbox1"
                      type="checkbox"
                      checked={isPaid}
                      onChange={handleToggleChange}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                <p>
                  {isPaid ? 'Paid' : 'Unpaid'}
                </p>
              </div>

            </div>
          </div>
          {/* <div className='notes' id='notes'>
            <p>Note</p>
            <textarea className='note' id='note' onChange={postUserData} value={userdata.note} name="note" placeholder='Write here...'>
            </textarea>
          </div> */}
          <div className='Submit'>
            <button onClick={goBack}>&nbsp; Back &nbsp;</button>
            <button type='submit'>Submit</button>
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
}
