import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import "./popup.css";
import Reject from "./images/Reject.gif";
import { Link } from "react-router-dom";

export default function Rejectpopupss() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [showConfetti, setShowConfetti] = useState(true);

    // Update the width and height on window resize
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    // Automatically stop the confetti after 5 seconds
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowConfetti(false);
        }, 10000); // Stop confetti after 5 seconds
        return () => clearTimeout(timer);
    }, []);
    return (
        <>
            {/* Confetti effect will show for 5 seconds */}
            <div className="success_popups" style={{backgroundColor:"#EFF1F3"}}>
                <div style={{ width: "100%" }}>
                    <div style={{ textAlign: "center", marginBottom: "20px" }}>
                        <img src={Reject} alt="Success" style={{width:"30%"}}/>
                    </div>
                    <h2 style={{ textAlign: "center", color: "#404040", fontSize: "30px", marginBottom: "10px" , color:"red" }}>
                        Your Payment Request is Failed !
                    </h2>
                    <p style={{ textAlign: "center", color: "#787878", fontSize: "15px", wordSpacing: "1px" }}>
                    Your in-app purchase could not be completed at this time. This might be due to an issue with your <br/> payment method or a temporary error.Please review your payment details and try again.
                    </p>
                    <div className="backtohome" style={{ marginTop: "30px" }}>
                        <Link to="/dashboard" >
                        <button style={{cursor:"pointer"}}> Back to Dashboard</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}
