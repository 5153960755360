import "./customer.css"
import Headers from "../Header/index";
import { Link, useNavigate } from "react-router-dom";
import firebase, { database, get, child, remove, getDatabase } from '../Login/config';
import React, { useEffect, useState } from "react";
import { ref, update } from "firebase/database";
import DatanotFound from './images/rMSD8h3gzM.gif';
import 'firebase/compat/auth';
import 'firebase/database';
import Sidebar from "../Sidebar/Sidebar";
import DashHeader from "../Dashbord/dashheader";
import Invoiceheaderiimage from "../Invoicedata/images/hading_image.png";
import Footer from "../Footer/Footer";

// Add popup box code start

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Helmet } from "react-helmet";

// Add popup box code end

export default function Coustomer() {
    const customer = localStorage.getItem("customer");
    const [datas, setData] = useState([]);
    const [company, setCompany] = useState("");
    let bildata = [];
    const UserId = localStorage.getItem("AccessUserId");
    // State variable for search query
    const [searchQuery, setSearchQuery] = useState("");

    // Firebase update data code start........
    const [cusname, setCusName] = useState("");
    const [cusemail, setCusEmail] = useState("");
    const [cusadress, setCusAdress] = useState("");
    const [cusgstnumber, setCusGstNumber] = useState("");
    const [cuscontectnumber, setCusContectNumber] = useState("");
    const [invoiceid, setInvoiceId] = useState("");
    const [firebaseinvoiceid, setFirebaseInvoiceId] = useState("");
    const [refreshData, setRefreshData] = useState(false);
    // Firebase update data code end..........

    // get data code start
    const getbilinvoice = () => {
        get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
            if (snapshot.exists()) {
                bildata = snapshot.val();
                console.log("bildata", bildata);
                const data = snapshot.val();
                setData(Object.entries(data.billInvoice));
                setCompany(Object.entries(data.company));
            }
        }).catch((error) => {
            console.error(error);
        });
    };

    // get data code end

    const updates = (invoice_id) => {
        localStorage.setItem("Update_Invoice_Id", invoice_id);
        document.getElementById('popups').style.display = "block";
        document.getElementById('popups').style.transition = "all 1s";
        setFirebaseInvoiceId(invoice_id);
    }
    const UpdateInvoiceId = localStorage.getItem("Update_Invoice_Id");
    let companydata = [];
    let updatedata = [];
    // const [names, setNames] = useState("");
    const [showdata, setShowData] = useState(false);

    const getbilinvoices = () => {
        get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
            if (snapshot.exists()) {
                bildata = snapshot.val().billInvoice;
                Object.keys(bildata).forEach(function (val) {
                    if (val == UpdateInvoiceId) {
                        updatedata = bildata[val];
                        setCusName(updatedata.customer);
                        setCusEmail(updatedata.email);
                        setCusAdress(updatedata.address);
                        setCusGstNumber(updatedata.customerGSTNo);
                        setCusContectNumber(updatedata.phone
                        );
                        setInvoiceId(updatedata.invoiceId);
                    }
                });

                companydata = snapshot.val().company;

                for (let index = 0; index < companydata.length; index++) {

                    const element = companydata[index];
                    console.log('element', element)
                }
            }
        }).catch((error) => {
            console.error(error);
        });
    };

    // Update data code start..........
    const handleSubmitChange = () => {

        update(child(database, `invoiceGenerator/users/${UserId}/billInvoice/${firebaseinvoiceid}/`), {
            customer: cusname,
            email: cusemail,
            address: cusadress,
            customerGSTNo: cusgstnumber,
            phone: cuscontectnumber
        })
        // setCusName("");
    };
    // Update data code end............

    useEffect(() => {
        getbilinvoices();
        getbilinvoice();
    }, [showdata, refreshData]);



    const close = () => {
        document.getElementById('popups').style.display = "none";
        document.getElementById('popups').style.transition = "all 1s";
        localStorage.removeItem(UpdateInvoiceId);
    }



    // confirmation popup box code start

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // confirmation popup box code end
    // Apply tax code start
    const [Taxs, setTax] = useState('');
    let Symbol = localStorage.getItem("symbol");
    let CurrencyCode = localStorage.getItem("currencyCode");
    const TaxType = () => {
        if (CurrencyCode == "EUR" && CurrencyCode == "CZK" && CurrencyCode == "DKK" && CurrencyCode == "HUF" && CurrencyCode == "PLN" && CurrencyCode == "RON" && CurrencyCode == "SEK" && CurrencyCode == "GBP") {
            setTax("VAT");
            localStorage.setItem("tax", Taxs);

        } else if (CurrencyCode == "INR") {
            setTax("GST");
            localStorage.setItem("tax", Taxs);
        }
        else {
            setTax("TAX id");
            localStorage.setItem("tax", Taxs);
        }
    }
    useEffect(() => {
        TaxType();
    }, [Taxs]);
    const [taxvalue, settaxvalue] = useState("");
    const handleClick = (value) => {
        settaxvalue(value);
    };
    const [discount, setdiscount] = useState("");
    const handleClicks = (value) => {
        setdiscount(value);
    };
    // Apply tax code end\
    const Customeplaceholder = "Enter" + " " + Taxs;

    // Function to handle search query change
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filtered data based on search query
    const filteredData = datas.filter((invo_data) => {
        return invo_data[1].customer?.toLowerCase().includes(searchQuery.toLowerCase());
    });

    return (
        <>
            <Helmet>
                <title>
                    Easily manage your Customer's Data With Our Invoice Generator Tool
                </title>
                {/* <meta
          name="description"
          content="Explore the Invoice mobile app case study by Softnoesis, a testament to our success in mobile development. Discover how we created a user-friendly invoicing solution, enhanced productivity, and streamlined business operations. Click now to dive into this transformative success story!"
        /> */}
                <link
                    rel="canonical"
                    href="https://app.billnama.com/customers"
                />
            </Helmet>
            <div className="dashbord">
                <div className="space">
                </div>
                <Sidebar />
                <div className="account_details">
                    <DashHeader />
                    <div className="invoice_info"  >
                        <div className="invoices_details">
                            <div className="invoices_head">
                                <div>
                                    <h2>Customer</h2>
                                    <ul>
                                        {/* <li>Dashbord</li>
                              <li>customer</li> */}
                                        <li id="Serch_customer">
                                            <div>
                                                <div>
                                                    <p>Search</p>
                                                </div>

                                                <i class="fa-solid fa-magnifying-glass" style={{ left: "15px" }}></i>
                                                <input type="search" placeholder="Search Customer" onChange={handleSearch} style={{ backgroundColor: "white" }} />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="Invoiceheaderiimage">
                                    {/* <img src={Invoiceheaderiimage} /> */}
                                </div>
                            </div>
                        </div>
                        <div id="main_section_business_invoice" style={{ position: "relative", padding: "0px" }}>
                            <div className="main_section" id="main_section_business" style={{ padding: "0px" }}>
                                <div className="bussiness_section_heading" id="Customer_section_heading" style={{ position: "sticky", top: "0px", width: "100%", left: "0px", padding: "15px", backgroundColor: "#3963AD", color: "white", borderRadius: "7px 7px 0px 0px", zIndex: "999", fontSize: "14px" }}>
                                    <p>Customer Name</p>
                                    <p>{Taxs} Number</p>
                                    <p>Phone No</p>
                                    <p>Email</p>
                                    <p>Action</p>
                                </div>

                                <div className="invoice_history" style={{ padding: "0px 15px" }}>
                                    {/* <Headers /> */}
                                    {UserId &&
                                        <div>
                                            {company ? company.map((company_id, ids) => {
                                                return (
                                                    <input type="hidden" name="BillInvoiceId" value={company_id[0]} />
                                                )
                                            }) : ""}
                                            {filteredData.length > 0 ? filteredData.map((invo_data, index) => {
                                                return (
                                                    <>
                                                        <div className="Customer">
                                                            <div className="Cus_his" id="Customer_history" style={{ margin: "0px" }}>
                                                                <div className="data">
                                                                    <p>{invo_data[1].customer}</p>
                                                                </div>
                                                                <div className="data">
                                                                    <p>{invo_data[1].customerGSTNo || <i className="fa-solid fa-minus" id="dash"></i>}</p>
                                                                </div>
                                                                <div className="data">
                                                                    <p>{invo_data[1].phone || <i className="fa-solid fa-minus" id="dash"></i>}</p>
                                                                </div>
                                                                <div className="data">
                                                                    <p>{invo_data[1].email || <i className="fa-solid fa-minus" id="dash"></i>}</p>
                                                                </div>

                                                                <div className="btn" >
                                                                    {/* <button onClick={() => { setShowData(!showdata); updates(invo_data[0]) }} value={invo_data[0]}><i className="fa-regular fa-pen-to-square"></i></button> */}


                                                                    <button class="edit-button" onClick={() => { setShowData(!showdata); updates(invo_data[0]) }} value={invo_data[0]}>
                                                                        <svg class="edit-svgIcon" viewBox="0 0 512 512">
                                                                            <path d="M410.3 231l11.3-11.3-33.9-33.9-62.1-62.1L291.7 89.8l-11.3 11.3-22.6 22.6L58.6 322.9c-10.4 10.4-18 23.3-22.2 37.4L1 480.7c-2.5 8.4-.2 17.5 6.1 23.7s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L387.7 253.7 410.3 231zM160 399.4l-9.1 22.7c-4 3.1-8.5 5.4-13.3 6.9L59.4 452l23-78.1c1.4-4.9 3.8-9.4 6.9-13.3l22.7-9.1v32c0 8.8 7.2 16 16 16h32zM362.7 18.7L348.3 33.2 325.7 55.8 314.3 67.1l33.9 33.9 62.1 62.1 33.9 33.9 11.3-11.3 22.6-22.6 14.5-14.5c25-25 25-65.5 0-90.5L453.3 18.7c-25-25-65.5-25-90.5 0zm-47.4 168l-144 144c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l144-144c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"></path>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }) : <>
                                                <div id="datanot_found">
                                                    <img src={DatanotFound} />
                                                </div>
                                            </>}
                                        </div>
                                        || customer &&

                                        <div className="Customer">
                                            <div className="Cus_his">
                                                <div className="data">
                                                    <h3>{customer}</h3>
                                                </div>
                                                <div className="btn">
                                                    <button ><i className="fa-regular fa-pen-to-square"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                        || <div id="datanot_found">
                                            <img src={DatanotFound} />
                                        </div>

                                    }
                                </div>

                                {/* Update popup HTML code start */}
                                <div className="popups" id="popups">

                                    <div className="details">
                                        {/* <div id="close">
                                            <p onClick={close}>✖</p>
                                        </div> */}
                                        <h2>Customer Edit</h2>
                                        <div className="field">
                                            <div>
                                                <div className="Customer_name">
                                                    <label>Name</label>
                                                    <input type='text' id='customer' value={cusname} onChange={(e) => { setCusName(e.target.value) }} name="customer" />
                                                </div>
                                                <div className="Customer_email">
                                                    <label>Email</label>
                                                    <input type='email' id='email' value={cusemail} onChange={(e) => { setCusEmail(e.target.value) }} name="email" />
                                                </div>

                                                <div className="Customer_GST">
                                                    <label>{Taxs}</label>
                                                    <input type='text' min="0" id='customerGSTNo' placeholder={Customeplaceholder} value={cusgstnumber} onChange={(e) => { setCusGstNumber(e.target.value) }} name="customerGSTNo" />
                                                </div>
                                                <div className="Customer_number">
                                                    <label >Phone</label>
                                                    <input type='number' min="0" id='phone' value={cuscontectnumber} onChange={(e) => { setCusContectNumber(e.target.value) }} name="phone" />
                                                </div>
                                                <div className="Customer_add">
                                                    <label>Address</label>
                                                    <textarea type="text" className='address' value={cusadress} onChange={(e) => { setCusAdress(e.target.value) }} id='address' name="address" ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn">
                                            <button onClick={() => { handleClickOpen(); close() }}>Update</button>
                                            <button onClick={close}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                                {/* Update popup HTML code End */}
                                <React.Fragment>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >

                                        <DialogContent style={{ textAlign: "center" }}>
                                            <DialogContentText id="alert-dialog-description">
                                                <p><i className="fa-solid fa-circle-exclamation" style={{ color: "red" }}></i></p>
                                                <h2>Update Customer</h2>
                                                <span>Are you sure you want to update your data.</span>
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions id="update_btn">
                                            <Button onClick={handleClose}>Cancel</Button>
                                            <Button onClick={() => { handleSubmitChange(); setRefreshData(!refreshData); handleClose() }} autoFocus>
                                                Update
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </React.Fragment>
                            </div>
                        </div>
                        <div className="allrights_recives">
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>

    );

}