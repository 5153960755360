import firebase, { database, get, child } from '../Login/config';
import "./solditem.css";
import Header from "../Header";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import DashHeader from "../Dashbord/dashheader";
import Invoiceheaderiimage from '../Invoicedata/images/hading_image.png';
import DatanotFound from '../Customer/images/rMSD8h3gzM.gif';
import { Helmet } from "react-helmet";
import Footer from "../Footer/Footer";

export default function Solditem() {

    const Symbol = localStorage.getItem('symbol');
    const [datas, setDatas] = useState([]);
    const [searchQuery, setSearchQuery] = useState([]);
    const UserId = localStorage.getItem("AccessUserId");
    const [companydata, setCompany] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [Maxtotle, setMaxtotle] = useState(0); // New state for the total value

    const getbilinvoice = () => {
        get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                setCompany(Object.entries(data.company));
                setDatas(Object.entries(data.billInvoice));
                setSearchQuery(Object.entries(data.billInvoice).flatMap(entry => entry[1].items));
            }
        }).catch((error) => {
            console.error(error);
        });
    };

    const filterdata = (search) => {
        const filteredItems = datas.flatMap(entry =>
            entry[1].items.filter(item =>
                item.name.toLowerCase().includes(search.toLowerCase())
            )
        );
        setSearchQuery(filteredItems);
    };

    const handleCompanyChange = (event) => {
        const companyId = Number(event.target.value);  // Convert to number here
        setSelectedCompany(companyId);
    };

    useEffect(() => {
        getbilinvoice();
    }, []);

    useEffect(() => {
        if (selectedCompany && datas.length > 0) {
            const selectedCompanyId = Number(selectedCompany);
            const newData = datas.filter(invo_data => {
                return invo_data[1].companyId === selectedCompanyId;
            });
            setFilteredData(newData);
            setIsLoading(false);
        } else {
            setFilteredData([]);
        }
    }, [selectedCompany, datas]);

    useEffect(() => {
        if (companydata && companydata.length > 0) {
            setSelectedCompany(companydata[0][1].companyId);
        }
    }, [companydata]);

    // Calculate total amount when filteredData or searchQuery changes
    useEffect(() => {
        let total = 0;
        (filteredData.length > 0 ? filteredData.flatMap(entry => entry[1].items) : searchQuery).forEach(item => {
            if (item.companyId == selectedCompany) {
                total += parseFloat(item.price);
            }
        });
        setTotalAmount(total);
    }, [filteredData, searchQuery, selectedCompany]);

    // Calculate total count when filteredData or searchQuery changes
    useEffect(() => {
        let total = 0;
        (filteredData.length > 0 ? filteredData.flatMap(entry => entry[1].items) : searchQuery).forEach(item => {
            if (item.companyId == selectedCompany) {
                total += parseFloat(item.quantity);
            }
        });
        setTotalCount(total);
    }, [filteredData, searchQuery, selectedCompany]);

    // Calculate total for Maxtotle (sum of item.price * item.quantity)
    useEffect(() => {
        let total = 0;
        (filteredData.length > 0 ? filteredData.flatMap(entry => entry[1].items) : searchQuery).forEach(item => {
            if (String(item.companyId) === String(selectedCompany) || !item.companyId) {
                total += item.price * (item.quantity || 1); // Calculate total for each item
            }
        });
        setMaxtotle(total);
    }, [filteredData, searchQuery, selectedCompany]);

    return (
        <>
            <Helmet>
                <title>
                    Easily Manage Your Sold Product details with Our Inventory Feature
                </title>
                <link
                    rel="canonical"
                    href="https://app.billnama.com/sold-products"
                />
            </Helmet>
            <div className="dashbord">
                <div className="space"></div>
                <Sidebar />
                <div className="account_details">
                    <DashHeader />
                    <div className="invoice_info">
                        <div className="invoices_details">
                            <div className="invoices_head">
                                <div>
                                    <h2>Sold items</h2>
                                    <ul>
                                        <li id="Serch_customer">
                                            <div>
                                                <div>
                                                    <p>Search</p>
                                                </div>

                                                <i className="fa-solid fa-magnifying-glass"></i>
                                                <input type="search" placeholder="Search Items" onChange={(e) => filterdata(e.target.value)} style={{ backgroundColor: "white" }} />
                                            </div>
                                        </li>

                                        <li className="dots">
                                            <div>
                                                <div className='business_dropdown_lable'>
                                                    <p>Business</p>
                                                </div>
                                                {UserId &&
                                                    <div className='business_dropdown'>
                                                        <i className="fa-solid fa-angle-down"></i>
                                                        <select onChange={handleCompanyChange} value={selectedCompany}>
                                                            {companydata ? companydata.map((company_data, i) => {
                                                                return (
                                                                    <option key={i} value={company_data[1].companyId}>{company_data[1].companyName}</option>
                                                                )
                                                            }) : ""}
                                                        </select>
                                                    </div>
                                                }
                                            </div>
                                        </li>

                                        <li className="dots" id="Filter">
                                            <div>
                                                <div>
                                                    <p>Filter</p>
                                                </div>
                                                <i className="fa-solid fa-angle-down"></i>
                                                <select id="Filter_type" placeholder="filter">
                                                    <option disabled>Filter</option>
                                                    <option>Last 7 Days</option>
                                                    <option>Current Month</option>
                                                    <option>Last Month</option>
                                                    <option>Custom Days</option>
                                                </select>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                </div>
                            </div>
                        </div>
                
                        <div style={{ height: "55vh", overflowY: "auto", backgroundColor: "white", borderRadius: "5px 5px 0px 0px", boxShadow: 'rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px', border: "1px solid rgb(223, 223, 223)", borderBottom: "none" }}>
                            <table style={{ width: "100%", textAlign: "left", backgroundColor: "white", padding: "10px", borderCollapse: "collapse", color: "dimgray", fontSize: "15px" }}>
                                <thead style={{ position: "sticky", top: "0", backgroundColor: "#3963AD", zIndex: "1", color: "black", color: "white", fontSize: "14px" }}>
                                    <tr>
                                        <th style={{ width: "40%", padding: "15px " }}>Items</th>
                                        <th style={{ width: "20%", padding: "15px" }}>Quantity</th>
                                        <th style={{ width: "20%", padding: "15px" }}>Price</th>
                                        <th style={{ width: "20%", padding: "15px" }}>Total Amount</th>
                                    </tr>
                                </thead>
                                <tbody style={{ margin: "0px" }}>
                                    {UserId && (filteredData.length > 0 || searchQuery.length > 0) ? (
                                        <>
                                            {(filteredData.length > 0
                                                ? filteredData.flatMap((entry) => entry[1].items)
                                                : searchQuery
                                            ).map((item, i) => (
                                                (String(item.companyId) === String(selectedCompany) || !item.companyId) ? (
                                                    <tr key={i} style={{borderBottom:"1px solid rgba(92, 92, 92, 0.10)"}}>
                                                        <td style={{ padding: "15px" }}>{item.name || 'No Name'}</td>
                                                        <td style={{ padding: "15px" }}>{item.quantity ? `${item.quantity}` : '0'}</td>
                                                        <td style={{ padding: "15px" }}>{Symbol} {item.price || 0}</td>
                                                        <td style={{ padding: "15px" }}>{Symbol} {item.price * (item.quantity || 1)}</td>
                                                    </tr>
                                                ) : null
                                            ))}
                                        </>
                                    ) : (
                                        <tr>
                                            <td colSpan="4" id="datanot_found">
                                                <img src={DatanotFound} alt="Data Not Found" />
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", borderRadius: "0px 0px 5px 5px", backgroundColor: "white", boxShadow: 'rgba(145, 158, 171, 0.3) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px', border: "1px solid rgb(223, 223, 223)", fontWeight:"bold" }}>
                            <table style={{ width: "100%", textAlign: "left", backgroundColor: "white",  borderCollapse: "collapse", color: "dimgray", fontSize: "15px" }}>
                                <tbody>
                                    <tr >
                                        <td style={{ width: "40%", padding: "8px 15px" }}>TOTAL</td>
                                        <td style={{ width: "20%" ,padding: "8px 15px"  }}>{totalCount}</td>
                                        <td style={{ width: "20%",padding: "8px 15px"   }}>{Symbol} {totalAmount}</td>
                                        <td style={{ width: "20%", padding: "8px 15px"   }}>{Symbol} {Maxtotle}</td> {/* Display total */}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
