import React, { useEffect, useState } from "react";
import "./Header.css";
import { Link, redirect } from "react-router-dom";
import logo from './images/logo.png';
// import DeviceDetector from "device-detector-js";


const Header = () => {

  // const deviceDetector = new DeviceDetector();
  // const userAgent = "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_13_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/69.0.3497.81 Safari/537.36";
  // const device = deviceDetector.parse(userAgent);
  // console.log(device);
  // const googleTranslateElementInit = () => {
  //   new window.google.translate.TranslateElement(
  //     {
  //       pageLanguage: "en",
  //       autoDisplay: false
  //     },
  //     "google_translate_element"
  //   );
  // };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  // setting icon
  const [visible, setVisible] = useState();
  const [show, setShow] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState();

  useEffect(() => {
    setUser(localStorage.getItem("email"));
  });
  const handallogout = () => {
    localStorage.clear("email");
    redirect("/");
    window.location = "/";
  }
  const companyName = localStorage.getItem("companyName");
  const sendEmail = () => {
    window.open("mailto:support@billnama.com?subject=SendMail&body=Description");
  };

  const Facebook = () => {
    window.open("https://www.facebook.com/invoicegeneratbill");
  }
  const Tearms = () => {
    window.open("https://softnoesis.com/apps/invoice/terms_privacy_policy.php");
  }
  return (
    <>
      <div className="Navbar">
        <span className="nav-logo" >
          <Link to="/">
            <img src={logo} />
          </Link>

        </span>
        {/* <div id="Nave"> */}
        <div className={`nav-items ${isOpen && "open"}`} id="nav">
          {/* <Link to="/simple-invoice-template">
            <span id="try-mobile"> My Invoice</span>

          </Link> */}
          {/* Laungage Start */}
          {/* <span id="google_translate_element"></span> */}
          {/* Laungage End */}

          <Link>
            {
              !user ?

                <Link to="/login" > <span id="signin_signup1">Sign in / Sign up</span></Link>
                : ""}
          </Link>
          <Link to="/dashboard">
            <span id="dashborad"> {user ? <span>Dashboard</span> : ""}</span>
          </Link>
          {/* <Link to="/subscriptions" className='sub'>
            <span >Subscriptions</span>
          </Link> */}
          <Link >
            <span onClick={() => setShow(!show)} > {user ? <span onClick={handallogout} style={{ color: "red" }}>Logout</span> : ""}</span>
            {
              // show&&
              // <div id="Setting">
              //   <ol>
              //       <Link href="mailto:support@billnama.com">
              //       <li onClick={sendEmail}> Feedback</li>
              //       </Link>
              //       {/* <li>Write review</li> */}
              //       {/* <li>Cool apps</li> */}
              //       {/* <li>Tell your frinds</li> */}
              //       <li onClick={Facebook}>Like us on Facebook</li>
              //       {/* <Link to="/session">
              //       <li>Session</li>
              //       </Link> */}
              //       <hr/>
              //       { user ? <li onClick={handallogout} style={{color:"red"}}>Logout</li> : ""}
              //   </ol>
              // </div>
            }
          </Link>
          {
            !user ?

              <Link to="/login" > <span id="signin_signup">Sign in / Sign up</span></Link>
              : ""}
        </div>

        {/* </div> */}
        <div
          className={`nav-toggle ${isOpen && "open"}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="bar"></div>
        </div>
      </div>

    </>
  );
};

export default Header;