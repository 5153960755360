import "./Invoicedata.css"
import Headers from "../Header/index";
import { Link, useNavigate } from "react-router-dom";
import firebase, { database, get, child, remove, getDatabase } from '../Login/config';
import React, { useEffect, useState } from "react";
import { ref, update } from "firebase/database";
import DatanotFound from './images/rMSD8h3gzM.gif';
import Invoiceheaderiimage from "./images/hading_image.png"
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/database';
import Sidebar from "../Sidebar/Sidebar";
import DashHeader from "../Dashbord/dashheader";
import Country from "../../COMPONENTS/countries.json";
import Currancy from "../../COMPONENTS/currency.json";
import Loader from '../loader/index';
import Footer from "../Footer/Footer";
import Devlopment from "../Still_devlopment_poppup/index";
// Add popup box code start;

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Helmet } from "react-helmet";

// Add popup box code end

export default function Invoicedata() {

   const selectedCompanyId = localStorage.getItem("selectedCompanyId");
   const companyName = localStorage.getItem("companyName");
   const navigate = useNavigate();
   const [datas, setData] = useState();
   const [companydata, setCompany] = useState();
   let bildata = [];
   const UserId = localStorage.getItem("AccessUserId");
   const [invoiceid, setInvoiceId] = useState("");
   const [firebaseinvoiceid, setFirebaseInvoiceId] = useState("");
   const [refreshData, setRefreshData] = useState(false);
   const [searchQuery, setSearchQuery] = useState("");

   const [selectedOption, setSelectedOption] = useState('');
   const handleSelectChange = (e) => {
      const selectedItemId = e.target.value;
      // Update state
      setSelectedOption(selectedItemId);
      // Update localStorage
      localStorage.setItem('selectedCompanyId', selectedItemId);
   };

   const [invoices, setInvoices] = useState([]);
   const [filterType, setFilterType] = useState('');
   // const [companydata, setCompany] = useState();
   const getbilinvoice = () => {
      get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
         if (snapshot.exists()) {
            const data = snapshot.val();
            if (data && data.billInvoice) {

               const invoiceArray = Object.entries(data.billInvoice).map(([key, value]) => ({
                  id: key,
                  ...value
               }));
               setInvoices(invoiceArray);
               setCompany(Object.entries(data.company));
               setData(Object.entries(data.billInvoice));
            } else {
               console.error("billInvoice data is undefined or null");
            }
         }
      }).catch((error) => {
         console.error(error);
      });
   };

   useEffect(() => {
      getbilinvoice();
   }, []);

   const Symbol = localStorage.getItem('symbol');

   //  View Invoice Data Code Start

   const handleClickes = (event, id) => {
      event.preventDefault();
      localStorage.setItem("createdInvoiceId", event.target.value);
      localStorage.setItem("company_id", id);
      navigate('/myinvoice');
   };
   //  View Invoice Data Code End

   const opens = (invoice_id) => {
      localStorage.setItem("invoice_id", invoice_id);
      document.getElementById('Delete').style.display = "block";
      console.log("invoice_id", invoice_id);

   }


   //  Delete Invoice Data Code Start
   const handledelete = event => {
      // Log the value of event.target.value for debugging

      // Set invoice ID in localStorage

      // Get invoice ID from localStorage
      const billinvoice_id = localStorage.getItem("invoice_id");
      // Log the value of billinvoice_id for debugging
      console.log("Invoice ID from localStorage:", billinvoice_id);

      // Remove data from Firebase Realtime Database
      remove(ref(getDatabase(), `invoiceGenerator/users/${UserId}/billInvoice/${billinvoice_id}`))
         .then(() => {
            console.log("Data deleted successfully.");
            // Reload the page after data deletion
            window.location.reload();
         })
         .catch(error => {
            console.error("Error deleting data:", error);
            // Handle error, if any
         });
   }


   const close = () => {
      document.getElementById('Delete').style.display = "none";

   }
   //  Delete Invoice Data Code End

   // confirmation popup box code start
   const [open, setOpen] = React.useState(false);

   const handleClickOpen = (invoice_id) => {
      localStorage.setItem("Update_Invoice_Id", invoice_id);
      setFirebaseInvoiceId(invoice_id);
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
   };
   // confirmation popup box code end


   const [items, setitems] = useState([{ item: "", dse: "", qty: "", price: "", amt: "" }]);
   const add_iteam = () => {
      setitems([...items, { item: "", dse: "", qty: "", price: "", amt: "" }]);
   }
   const remove_iteam = (index) => {
      const list = [...items];
      list.splice(index, 1);
      setitems([...list]);
   }

   let total = 0;

   // Apply tax code start

   const [Taxs, setTax] = useState('');
   let CurrencyCode = localStorage.getItem("currencyCode");
   const TaxType = () => {
      if (CurrencyCode == "EUR" && CurrencyCode == "CZK" && CurrencyCode == "DKK" && CurrencyCode == "HUF" && CurrencyCode == "PLN" && CurrencyCode == "RON" && CurrencyCode == "SEK" && CurrencyCode == "GBP") {
         setTax("VAT");
      } else if (CurrencyCode == "INR") {
         setTax("GST");
      }
      else {
         setTax("TAX");
      }
   }
   useEffect(() => {
      TaxType();
   }, [Taxs]);
   const [taxvalue, settaxvalue] = useState("");
   const handleClick = (value) => {
      settaxvalue(value);
   };
   const [discount, setdiscount] = useState("");
   const handleClicks = (value) => {
      setdiscount(value);
   };

   // Apply tax code end


   // tax and discount code start

   const TotalAmount = localStorage.getItem("total");
   const Growprice = parseInt(TotalAmount);
   const GST = (Growprice * taxvalue / 100);
   const gstAmount = GST;
   const gstPercetage = taxvalue;
   const TaxAmount = (Growprice + GST).toFixed(2);;
   const discountAmount = (TaxAmount * discount / 100).toFixed(2);
   const discountPercetage = discount;
   localStorage.setItem("DiscountAmounts", discountAmount);
   const totalAmount = (Growprice + GST - discountAmount).toFixed(2);
   localStorage.setItem("totalAmount", totalAmount);

   // tax and discount code end
   const handleSearch = (event) => {
      setSearchQuery(event.target.value);
   };
   // paid unpaid start

   // Initialize state variable for checkbox value

   const [isPaid, setIsPaid] = useState(false);

   const handleToggleChange = () => {
      const newValue = !isPaid;
      setIsPaid(newValue);
      // paidFlagKey
      // console.log(newValue);
   };
   const paidFlagKey = isPaid;
   const Customeplaceholder = "Enter" + Taxs;
   // paid unpaid end

   //   currency start
   const [selectedItem, setSelectedItem] = useState(null);
   //   const [searchQuery, setSearchQuery] = useState("");
   const [filteredCountries, setFilteredCountries] = useState(Country.CurrencyResponse);
   const handleItemClick = (result) => {
      setSelectedItem(result.name);
      console.log('selectedItem', selectedItem);
      localStorage.setItem('selectedItem', result.name);
   };

   //   currency End

   function formatDate(timestamp) {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      let date;

      // Check if input is a Unix timestamp (integer or string)
      if (!isNaN(timestamp)) {
         if (timestamp.toString().length === 10) {
            // If it's 10 digits long, treat it as seconds and convert to milliseconds
            date = new Date(timestamp * 1000);
         } else if (timestamp.toString().length === 13) {
            // If it's 13 digits long, treat it as milliseconds
            date = new Date(parseInt(timestamp, 10));
         }
      } else {
         // Try to parse date string in various formats
         if (/\d{2}-\d{2}-\d{4}/.test(timestamp)) {
            // If format is dd-mm-yyyy
            const [day, month, year] = timestamp.split('-');
            date = new Date(`${year}-${month}-${day}`);
         } else if (/\d{4}-\d{2}-\d{2}/.test(timestamp)) {
            // If format is yyyy-mm-dd
            date = new Date(timestamp);
         } else if (/\d{2}-\d{2}-\d{2}/.test(timestamp)) {
            // If format is dd-mm-yy
            const [day, month, year] = timestamp.split('-');
            const fullYear = `20${year}`; // Assuming it's 2000+
            date = new Date(`${fullYear}-${month}-${day}`);
         } else {
            // Fall back to creating Date from any valid date string
            date = new Date(timestamp);
         }
      }

      if (!date || isNaN(date.getTime())) {
         return 'Invalid Date'; // Handle invalid date inputs
      }

      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      return `${day} ${months[monthIndex]}, ${year}`;
   }


   // Filter data using company name code start...

   const [selectedCompany, setSelectedCompany] = useState(); // State to hold selected company ID
   const [filteredData, setFilteredData] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   // Function to handle change in dropdown
   const handleCompanyChange = (event) => {
      const companyId = event.target.value; // Capture the selected company ID
      setSelectedCompany(companyId); // Update state
   };
   // console.log(filteredData,"filteredData");

   useEffect(() => {
      if (selectedCompany && datas.length > 0) {
         const newData = datas.filter(invo_data => {
            // Check if companyId matches either as a string or number
            return invo_data[1].companyId === selectedCompany ||
               invo_data[1].companyId === Number(selectedCompany);
         });
         setFilteredData(newData); // Update the filtered data
         setIsLoading(false); // Set loading to false
      } else {
         setFilteredData([]); // Reset filtered data if no selection
         setIsLoading(false); // Ensure loading is false in this case as well
      }
   }, [selectedCompany, datas]);

   // Render component logic...


   useEffect(() => {
      if (companydata && companydata.length > 0) {
         setSelectedCompany(companydata[0][1].companyId);
      }
   }, [companydata]);



   const [sortBy, setSortBy] = useState(""); // State to hold the sorting criteria

   // Event handler for dropdown change
   const handleSortChange = (event) => {
      setSortBy(event.target.value);
   }

   // Sort function based on different criteria
   const sortData = (data, sortBy) => {
      switch (sortBy) {
         case "Sort By Name":
            return data.sort((a, b) => a[1].customer.localeCompare(b[1].customer));
         case "Sort By Date":
            return data.sort((a, b) => new Date(a[1].creationDate) - new Date(b[1].creationDate));
         case "Sort By Amount":
            return data.sort((a, b) => a[1].totalAmount - b[1].totalAmount);
         case "Sort By Paid":
            const paidData = data.filter(item => item[1].paidFlagKey.toString() === 'true');
            const unpaidData = data.filter(item => item[1].paidFlagKey.toString() !== 'true');
            return [...paidData.sort(), ...unpaidData.sort()];
         case "Sort By Unpaid":
            const unpaidDataFirst = data.filter(item => item[1].paidFlagKey.toString() !== 'true');
            const paidDataFirst = data.filter(item => item[1].paidFlagKey.toString() === 'true');
            return [...unpaidDataFirst.sort(), ...paidDataFirst.sort()];
         default:
            return data;
      }
   }

   // useEffect to set default sorting when component mounts
   useEffect(() => {
      setSortBy("Sort By Name");
   }, []);

   const Show = () => {
      document.getElementById("devlopment-popups").style.display = "block";
   }

   // count how many paid and unpaid data  start
   const [paidCount, setPaidCount] = useState(0);
   const [unpaidCount, setUnpaidCount] = useState(0);

   // Count paid and unpaid items and store in local storage on component mount
   useEffect(() => {
      if (datas && datas.length > 0) {
         let paidCount = 0;
         let unpaidCount = 0;

         datas.forEach(invo_data => {
            if (invo_data[1].paidFlagKey.toString() === 'true') {
               paidCount++;
            } else {
               unpaidCount++;
            }
         });

         // Store the counts in local storage
         localStorage.setItem('paidCount', paidCount);
         localStorage.setItem('unpaidCount', unpaidCount);

         // Update state
         setPaidCount(paidCount);
         setUnpaidCount(unpaidCount);
      }
   }, [filteredData]);


   // count how many paid and unpaid data  End  

   // Date wise filter code start here............

   const filterInvoices = (filter) => {
      const now = new Date();
      let filtered = [];

      switch (filter) {
         case 'Last 7 Days':
            filtered = invoices.filter((invoice) => {
               const invoiceDate = new Date(invoice.creationDate);
               const differenceInTime = now.getTime() - invoiceDate.getTime();
               const differenceInDays = differenceInTime / (1000 * 3600 * 24);
               return differenceInDays <= 7;
            });
            break;
         case 'Current Month':
            filtered = invoices.filter((invoice) => {
               const invoiceDate = new Date(invoice.creationDate);
               return (
                  invoiceDate.getMonth() === now.getMonth() &&
                  invoiceDate.getFullYear() === now.getFullYear()
               );
            });
            break;
         case 'Last Month':
            const lastMonth = new Date(now.getFullYear(), now.getMonth() - 1, 1);
            filtered = invoices.filter((invoice) => {
               const invoiceDate = new Date(invoice.creationDate);
               return (
                  invoiceDate.getMonth() === lastMonth.getMonth() &&
                  invoiceDate.getFullYear() === lastMonth.getFullYear()
               );
            });
            break;
         case 'Custom Days':
            // Implement custom days filter logic if needed
            break;
         default:
            filtered = invoices;
      }

      setFilteredData(filtered);
   };



   //  Date wise filter code end here...........

   return (
      <>

         <Helmet>
            <title>
               Manage Your Invoices Data Easily with our Invoice Generator Tool
            </title>
            {/* <meta
          name="description"
          content="Explore the Invoice mobile app case study by Softnoesis, a testament to our success in mobile development. Discover how we created a user-friendly invoicing solution, enhanced productivity, and streamlined business operations. Click now to dive into this transformative success story!"
        /> */}
            <link
               rel="canonical"
               href="https://app.billnama.com/invoices"
            />
         </Helmet>

         <div className="dashbord">
            <div className="space">
            </div>
            <Sidebar />
            {isLoading ? (
               // Loader component here
               <Loader />
            ) : (
               <>
                  <div className="account_details">
                     <DashHeader />

                     <div className="invoice_info">
                        <div className="invoices_details">
                           <div className="invoices_head">
                              <div>
                                 <h2>Invoices</h2>
                                 <ul>
                                    {/* <li>Dashbord</li> */}
                                    {/* <li>invoices</li> */}
                                    <li id="Serch_customer" className="dots">
                                       <div>
                                          <div>
                                             <p>Search</p>
                                          </div>
                                          <i class="fa-solid fa-magnifying-glass"></i>
                                          <input type="search" placeholder="Search Invoice" onChange={handleSearch} style={{ backgroundColor: "white" }} />
                                       </div>
                                    </li>
                                    <li className="dots">
                                       <div>
                                          <div className="business_dropdown_lable">
                                             <p>Business</p>
                                          </div>
                                          {UserId &&
                                             <div className='business_dropdown' >
                                                <i className="fa-solid fa-angle-down"></i>
                                                <select onChange={handleCompanyChange} value={selectedCompany} style={{ minWidth: "150px" }}>

                                                   {companydata ? companydata.map((company_data, i) => {
                                                      return (
                                                         <option key={i} value={company_data[1].companyId}>{company_data[1].companyName}</option>
                                                      )
                                                   }) : ""}
                                                </select>
                                             </div>
                                          }
                                       </div>
                                    </li>
                                    <li className="dots">
                                       <div>
                                          <div className="sortby_dropdown_lable">
                                             <p>Sort by</p>
                                          </div>
                                          <div className='another_dropdown'>
                                             <i className="fa-solid fa-angle-down"></i>
                                             <select className='dropdown' onChange={handleSortChange}>
                                                {/* Populate options for the second dropdown */}
                                                <option disabled>Choose Option</option>
                                                <option>Sort By Name </option>
                                                <option>Sort By Date</option>
                                                <option>Sort By Amount</option>
                                                <option>Sort By Paid</option>
                                                <option>Sort By Unpaid</option>
                                             </select>
                                          </div>
                                       </div>
                                    </li>
                                 </ul>
                              </div>
                              <div>
                              </div>
                              <div>
                                 <ul>
                                    <div>
                                       <li className="dots" id="Filter">
                                          <div>
                                             <div>
                                                <p>Filter</p>
                                             </div>
                                             <i className="fa-solid fa-angle-down"></i>
                                             <select id="Filter_type" placeholder="filter"
                                                value={filterType}
                                                onChange={(e) => setFilterType(e.target.value)}
                                             >
                                                <option disabled>Filter</option>
                                                <option value="Last 7 Days">Last 7 Days</option>
                                                <option value="Current Month">Current Month</option>
                                                <option value="Last Month">Last Month</option>
                                                <option value="Custom Days">Custom Days</option>
                                             </select>
                                          </div>
                                       </li>
                                    </div>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="invoice_info">
                        <div className="main_section" id="main_section" style={{ position: "relative", padding: "0px" }}>
                           <div className="main_section_heading" style={{ position: "sticky", top: "0px", width: "100%", left: "0px", padding: "15px", backgroundColor: "#3963AD", color: "white", borderRadius: "7px 7px 0px 0px", zIndex: "999", }}>
                              <div >
                                 <p >Id</p>
                                 <p>Customer Name</p>
                                 <p>Date</p>
                                 <p>Amount</p>
                                 <p>Status</p>
                              </div>
                              <div >
                                 <p style={{ color: "white" }}>
                                    Action
                                 </p>
                              </div>
                           </div>

                           {UserId &&
                              <div className="fiebase_details" style={{ padding: "0px 15px" }}>

                                 {filteredData && filteredData.length > 0 && sortData(filteredData, sortBy).some(invo_data => invo_data[1].customer.toLowerCase().includes(searchQuery.toLowerCase())) ? (
                                    sortData(filteredData, sortBy).map((invo_data, index) => {
                                       const timestamp = invo_data[1].date;
                                       if (invo_data[1].customer?.toLowerCase().includes(searchQuery.toLowerCase())) {

                                          return (
                                             <>
                                                <div className="data_his" id="data_his" key={index}>
                                                   <div className="data">
                                                      <div className="invoice_data" style={{ fontSize: "14px" }}>
                                                         <p><button onClick={(event) => handleClickes(event, invo_data[1].companyId)} data-value={invo_data[1].companyId} value={invo_data[0]}>#{invo_data[1].id}</button></p>

                                                         <p><button onClick={(event) => handleClickes(event, invo_data[1].companyId)} data-value={invo_data[1].companyId} value={invo_data[0]}>{invo_data[1].customer}</button></p>

                                                         <p><button onClick={(event) => handleClickes(event, invo_data[1].companyId)} data-value={invo_data[1].companyId} value={invo_data[0]}>{formatDate(timestamp)}</button></p>

                                                         <p><button onClick={(event) => handleClickes(event, invo_data[1].companyId)} data-value={invo_data[1].companyId} value={invo_data[0]}>{Symbol}  {invo_data[1].totalAmount}</button></p>
                                                         <p>{(invo_data[1].paidFlagKey).toString() == 'true' && <lable id="paid"> paid </lable> || <lable id="unpaid"> unpaid </lable>}</p>
                                                         {/* <p></p> */}
                                                      </div>
                                                   </div>
                                                   <div className="btn">
                                                      {/* <button onClick={() => { handleClickOpen(invo_data[0]); close() }}><i className="fa-regular fa-pen-to-square"></i></button> */}

                                                      <button
                                                         onClick={Show}
                                                         disabled={invo_data[1].paidFlagKey.toString() === 'true'}
                                                         style={{
                                                            opacity: invo_data[1].paidFlagKey.toString() === 'true' ? 0.5 : 1,
                                                            cursor: invo_data[1].paidFlagKey.toString() === 'true' ? 'no-drop' : 'pointer',
                                                         }}
                                                      >
                                                         <i className="fa-regular fa-pen-to-square"></i>
                                                      </button>


                                                      <button onClick={(event) => handleClickes(event, invo_data[1].companyId)} data-value={invo_data[1].companyId} value={invo_data[1].invoiceId} className="fa-solid fa-eye"></button>

                                                      <button onClick={() => opens(invo_data[0])}>
                                                         <i className="fa-solid fa-trash"></i>
                                                      </button>

                                                      {/* <button onClick={handledelete} value={invo_data[0]}><i className="fa-solid fa-trash"></i></button> */}
                                                   </div>
                                                </div>

                                                {/* Delete popup HTML code start here.... */}
                                                <div id="Delete" style={{ zIndex: "99999" }}>
                                                   <div className="delete_section">
                                                      <div>
                                                         <p><i className="fa-solid fa-circle-exclamation"></i></p>
                                                         <h2>Delete Invoice</h2>
                                                         <span>Are You sure You Want to Delete!</span>
                                                         <div className="delete_btn">
                                                            <button onClick={handledelete} value={invo_data[0]}>Delete</button>
                                                            <button onClick={close}>Cancel</button>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                {/* Delete popup HTML code end here.. */}

                                                {/* Update popup HTML code start here.. */}
                                                <React.Fragment>
                                                   <Dialog
                                                      open={open}
                                                      onClose={handleClose}
                                                      aria-labelledby="alert-dialog-title"
                                                      aria-describedby="alert-dialog-description"

                                                   >

                                                      <DialogContent id="dilog_box">
                                                         <DialogContentText id="alert-dialog-description">

                                                            <div className='Form'>
                                                               <form >
                                                                  <div className='Bill-item' id='Bill-item'>
                                                                     <div className='items_heading'>
                                                                        <div className='items_heding'>
                                                                           <span>Item Name</span>
                                                                        </div>
                                                                        <div className='items_fulldetails'>
                                                                           <p>
                                                                              <select>
                                                                                 <option>Quantity</option>
                                                                                 <option>Hours</option>
                                                                              </select>
                                                                           </p>
                                                                           {/* Quantity / Hours */}
                                                                           <p>Rate</p>
                                                                           <p>AMOUNT</p>
                                                                        </div>
                                                                     </div>

                                                                     {items.map((item, index) => {
                                                                        total = total + item.amt;
                                                                        localStorage.setItem("total", total);
                                                                        return (
                                                                           <>
                                                                              <div className='Add-item' id='add-iteam'>

                                                                                 <div className='Item'>
                                                                                    <input
                                                                                       name="item"
                                                                                       placeholder='Enter Item Name'
                                                                                    />

                                                                                 </div>
                                                                                 <div className='Quantity'>
                                                                                    <input type='number' min="0" name='qty' placeholder='0' />

                                                                                    {/* <label>Quantity/Hours*</label> */}
                                                                                 </div>
                                                                                 <div className='Price'>
                                                                                    <input type='number' min="0"
                                                                                       name='price'
                                                                                       placeholder='00.00' />

                                                                                    {/* <label>Rate*</label> */}
                                                                                 </div>
                                                                                 <div className='Grows-price' id="amount">
                                                                                    {/* <p >Amount</p> */}
                                                                                    <span>{item.price * item.qty || item.qty * item.price}</span>
                                                                                 </div>
                                                                                 {index == 0 ? (<div className='remove' style={{ visibility: "hidden" }}>
                                                                                    <i className="fa-solid fa-xmark" onClick={() => remove_iteam(index)}></i>
                                                                                 </div>) : (<div className='remove'>
                                                                                    <i className="fa-solid fa-xmark" onClick={() => remove_iteam(index)}></i>
                                                                                 </div>)}
                                                                              </div>

                                                                              <div>
                                                                                 <div className='Description' id='Description'>
                                                                                    <input placeholder='Enter Item Details' />
                                                                                 </div>
                                                                              </div>
                                                                           </>
                                                                        );
                                                                     })}
                                                                     <div className='new-add' id='new-add'>
                                                                        <button type="button" onClick={add_iteam}>
                                                                           <span className='pluse'>+</span>
                                                                           <span>Add a New item</span>
                                                                        </button>
                                                                     </div>
                                                                  </div>
                                                                  <div className='Total-price'>
                                                                     <div className='notes' id='notes'>
                                                                        <p>Note</p>
                                                                        <textarea className='note' id='note' name="note" placeholder='Write here...'>
                                                                        </textarea>
                                                                     </div>
                                                                     <div className='Total-price-details'>
                                                                        <div className='curency' id="customer_curency">
                                                                           <select id="custmer_currency_value">
                                                                              {filteredCountries.length > 0 ? filteredCountries.map((result, index) => (
                                                                                 <>
                                                                                    <option className="CurrencyResponse" key={index}>
                                                                                       <span >{result.currency.code}&nbsp;</span>
                                                                                       <span>({result.currency.symbol})</span>
                                                                                    </option>
                                                                                 </>
                                                                              )) : <>
                                                                                 <div id="datanot_found">
                                                                                    <h2>Data not found</h2>
                                                                                 </div>
                                                                              </>
                                                                              }
                                                                           </select>
                                                                        </div>
                                                                        <div className='Price'>
                                                                           <p>Gross Amount</p>
                                                                           <p>{Symbol} {total} </p>
                                                                        </div>
                                                                        <div className='gst'>
                                                                           <p>{Taxs} </p>
                                                                           <p>
                                                                              <b>
                                                                                 <select onChange={(e) => { handleClick(e.currentTarget.value) }}>{Array.from(Array(100), (e, i) => {
                                                                                    return (
                                                                                       <option value={i} >{i} </option>
                                                                                    );
                                                                                 })};
                                                                                 </select>
                                                                                 <span> % </span>
                                                                              </b>
                                                                           </p>
                                                                           <p>{GST}</p>
                                                                        </div>
                                                                        <div className='discount'>
                                                                           <p>Discount</p>
                                                                           <p>
                                                                              <b>
                                                                                 <select onChange={(e) => { handleClicks(e.currentTarget.value) }}>
                                                                                    {Array.from(Array(100), (e, i) => {
                                                                                       return (
                                                                                          <option value={i}>{i}</option>
                                                                                       )
                                                                                    })}
                                                                                 </select>
                                                                                 <span> % </span>
                                                                              </b>
                                                                           </p>
                                                                           <p>{discountAmount}</p>
                                                                        </div>
                                                                        <div className='Net-Amount'>
                                                                           <p><b>Payable Amount</b></p>
                                                                           <p><b>{Symbol}{totalAmount}  </b></p>
                                                                        </div>
                                                                        <div className='paid_unpaid'>
                                                                           <div>
                                                                              <label class="switch">
                                                                                 <input
                                                                                    id="checkbox1"
                                                                                    type="checkbox"
                                                                                    checked={isPaid}
                                                                                    onChange={handleToggleChange}
                                                                                 />
                                                                                 <span class="slider round"></span>
                                                                              </label>
                                                                           </div>
                                                                           <p>
                                                                              {isPaid ? 'Paid' : 'Unpaid'}
                                                                           </p>
                                                                        </div>
                                                                     </div>
                                                                  </div>
                                                               </form>
                                                            </div>
                                                         </DialogContentText>
                                                      </DialogContent>
                                                      <DialogActions id="update_btn">
                                                         <Button onClick={handleClose}>Cancel</Button>
                                                         <Button autoFocus>
                                                            Update
                                                         </Button>
                                                      </DialogActions>
                                                   </Dialog>
                                                </React.Fragment>
                                                {/* Update popup HTML code end here.. */}
                                             </>
                                          )
                                       } else {
                                          return null;
                                       }
                                    })
                                 ) : (
                                    <div id="datanot_found">
                                       <img src={DatanotFound} alt="Data Not Found" />
                                    </div>
                                 )}
                              </div>
                              || (
                                 <div id="datanot_found">
                                    <img src={DatanotFound} />
                                 </div>
                              )
                           }
                        </div>
                        <div className="allrights_recives">
                           <Footer />
                        </div>
                     </div>
                     <Devlopment />
                  </div>


               </>
            )}
         </div>
      </>

   );
}