import  Headers  from './Header'

export default function Header() {
  return (
    <div>
        <Headers/>
    </div>
  );
}

