import "./session.css";
import Headers from "../Header/index";
import { Link, useNavigate } from "react-router-dom";
import firebase, { database, get, child, remove, getDatabase } from '../Login/config';
import { useEffect, useState } from "react";
import { ref, update } from "firebase/database";
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/database';
import Footer from "../Footer/index";

export default function Select() {
  const [datas, setDatas] = useState([]);
  let bildata = [];
  const UserId = localStorage.getItem("AccessUserId");

  const getbilinvoice = () => {
    get(child(database, `invoiceGenerator/users/${UserId}`)).then((snapshot) => {
      if (snapshot.exists()) {
        bildata = snapshot.val().session;
        // bildata = snapshot.val().billInvoice;
        // const data = Object.entries(snapshot.val().session);
        const data = snapshot.val();
        setDatas(Object.entries(data.session));
        // console.log('Object.entries(data.session)', Object.entries(data.session))
        // setDatas(Object.entries(data.session));
        // setCompany(Object.entries(data.session));
      }
    }).catch((error) => {
      console.error(error);
    });
  };
  useEffect(() => {
    getbilinvoice();
  }, []);

  let childs = [];
  return (
    <>
      <Headers />
      <div className="session">
        {datas ? datas.map((invo_data, index) => {
    childs = invo_data;
  //  { console.log('childs', childs)}
          return(

            <>
          {/* <h1>{invo_data}</h1> */}

            </>
            )
        }) : <>
          <h2>data no found</h2>
        </>}
      </div>
    </>
  );
}