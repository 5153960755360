import React from "react";
import "./privacy.css";
import { Link } from "react-router-dom";
import Headers from "../Header/index";
import Footer from "../Footer/index";
import Logo from "./images/Billnama_logo_bg_1024X1024 (1).jpg";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
    return (
        <>
            <Helmet>
                <title>Contact Us | BillNama</title>
                <meta
                    name="description"
                    content="Get in touch with BillNama's customer support team for assistance, inquiries, or feedback. We're here to help you with any questions you may have."
                />
                <link rel="canonical" href="https://billnama.com/contact-us"/>
            </Helmet>


            <Headers />
            <div class="context">
                <h1>Contact us</h1>
            </div>


            <div class="area" >
                <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div >
            <head >
                <meta property="og:url" content="https://www.softnoesis.com/apps/invoice/privacy_policy.php" />
                <meta property="fb:app_id" content="1515597091873107" />
                <meta property="og:title" content="Privacy Policy: BillNama, Bill Generator" />
                <meta property="og:description" content="BillNama, Bill Generator" />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="Privacy Policy: BillNama, Bill Generator" />
                <meta property="og:image" content="https://is2-ssl.mzstatic.com/image/thumb/Purple122/v4/11/00/66/11006672-0614-b999-d907-5c7ca9bdfedf/AppIcon-1x_U007emarketing-0-7-0-85-220.png/246x0w.webp" />
            </head>
            <div id="privacy_police" style={{ padding: '20px', marginLeft: '5%', marginRight: '5%' }} >
                <img src={Logo}/>
                {/* <img src="https://is2-ssl.mzstatic.com/image/thumb/Purple122/v4/11/00/66/11006672-0614-b999-d907-5c7ca9bdfedf/AppIcon-1x_U007emarketing-0-7-0-85-220.png/246x0w.webp" alt="Invoice maker application" /> */}
                <h3 style={{ marginBottom: 30, fontSize: 20 }}>Softnoesis Private Limited </h3>
                <p style={{ marginBottom: 10 }}>Nr Fayabda Bazar, Nanavat Main Road Surat, GJ</p>
                <p style={{ marginBottom: 10 }}>Contact: 7201070054</p>
                <p style={{ marginBottom: 150 }}>Email: <Link>support@billnama.com</Link></p>
            </div>
            <Footer />
        </>
    );
}