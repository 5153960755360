import { Link } from 'react-router-dom';
import './Devlopment_poppup.css';
import  Devlopment from "./images/Gif.gif";
import { useNavigate } from 'react-router-dom';
export default function Devlopmentpoppup() {
  const navigate = useNavigate();

  const Hidedetails =()=>{
    document.getElementById("devlopment-popups").style.display="none";
  }
    return (
      <>
        <div className='devlopment-popups' id="devlopment-popups">
          <div>
            <h2>This feature is coming soon!</h2>
            <p> Thank you for your patience as we work to enhance your experience. If you have any questions, please contact us at <Link to="mailto:support@billnama.com">support@billnama.com</Link> for clarification</p>
               <img src={Devlopment}/>
              
               <button onClick={Hidedetails}>Back to Previous Page</button>
               
          </div>   
        </div>
      </>
    );
  }   