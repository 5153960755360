import React from "react";
import "./privacy.css";
import Settingicon from "./images/icon-park-solid_setting-one (1).png";
import Settingicon_half from "./images/icon-park-solid_setting-one (2).png";
import Logo from "./images/Billnama_logo_bg_1024X1024 (1).jpg"
import { Link } from "react-router-dom";
import Headers from "../Header/index";
import Footer from "../Footer/index";
import { Helmet } from "react-helmet";

export default function PrivacyPolicy() {
    return (
        <>
            <Helmet>
                <title>Privacy Statement | BillNama</title>
                <meta
                    name="description"
                    content="Learn about how BillNama collects, uses, and protects your personal information. Read our privacy statement to understand our commitment to your privacy.
                    "
                />
                <link rel="canonical" href="https://billnama.com/privacy-statement" />
            </Helmet>
            <Headers />
            <head >
                <meta property="og:url" content="https://www.softnoesis.com/apps/invoice/privacy_policy.php" />
                <meta property="fb:app_id" content="1515597091873107" />
                <meta property="og:title" content="Privacy Policy: BillNama, Bill Generator" />
                <meta property="og:description" content="BillNama, Bill Generator" />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="Privacy Policy: BillNama, Bill Generator" />
                <meta property="og:image" content="https://is2-ssl.mzstatic.com/image/thumb/Purple122/v4/11/00/66/11006672-0614-b999-d907-5c7ca9bdfedf/AppIcon-1x_U007emarketing-0-7-0-85-220.png/246x0w.webp" />
            </head>

            {/* <div className="heading_box">
                <img src={Settingicon} className="full_settingicon"/>
                <img src={Settingicon_half} className="half_setting"/>
                    <h2>Privacy Statement</h2>
            </div> */}

            <div class="context">
                <h1>Privacy Statement</h1>
            </div>

            <div class="area" >
                <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div >
            <div id="privacy_police" style={{ padding: '20px', marginLeft: '5%', marginRight: '5%' }} >
                <p >Last updated: 05th sep, 2024
                    <br />BillNama is one of the Saas product of Softnoesis Pvt. Ltd., and its affiliates (“Company,” “Softnoesis” “BillNama” “we,” “us,” “app” or “our”) respect your privacy and are committed to protecting it through our compliance with this policy. By using our Services, you understand that we will collect and use your personal information as described in this Privacy Policy.</p>
                <img src={Logo} />
                {/* <img src="https://is2-ssl.mzstatic.com/image/thumb/Purple122/v4/11/00/66/11006672-0614-b999-d907-5c7ca9bdfedf/AppIcon-1x_U007emarketing-0-7-0-85-220.png/246x0w.webp" alt="Invoice maker application" /> */}
                <br />
                <h2>Privacy Policy</h2>
                <h3>Where does it store all the bills/invoices?</h3>
                <p>
                    Invoice application is storing all the files to its own document directory of iPhone as default. If you login with Facebook or Google then it will store Google Firebase server as well.
                </p>
                <h3>Does this app need the Internet?</h3>
                <p>
                    By default, this app does not need the Internet as it can store all the bills locally on the iPhone. But If you login with Facebook/Google then it needs the Internet.
                </p>
                <h3>Is user bills/invoices stored at a secure place?</h3>
                <p>We are storing data locally on iPhone and other places on Google Firebase if a user logged in the app. Locally, it is always secure. On the server side, Google Firebase has the strongest security in the world. So user data is secured here.</p>

                <h3>Personal Information We Collect About You And How We Collect It</h3>
                <p>We are collecting basic user details such as email, name, account id from your Facebook/Google/Apple account during the login/signup process.
                </p>
                <p>
                    We are also asking for your business information like name, address, tax identification number, phone.
                </p>
                <p>
                    We are storing it first on the application local database. As soon as an application gets into the internet, it will send that data to Firebase automatically. Here, the local database will be deleted as soon as the application is uninstalled or the user manually logs out.
                </p>

                <h3>Purpose of processing data?</h3>
                <p>We need account id as unique Id for each user data prior to storing all the users organizations, invoices and its corresponding items. Rest of the details like name and email are used for application interface.</p>

                <h3>Disclosure Of Your Personal Information</h3>
                <p><b>a.</b> We do not disclose your personal information to any third party in a manner that would be considered a sale under applicable laws.
                </p>
                <p>
                    <b>b.</b> We disclose or share your personal information only as follows:
                    <br /> <b>•</b> We share your personal information with our affiliates. These companies use your personal information as described in this Privacy Policy.
                    <br /><b>•</b> We share your personal information with other companies we use to support our Services. This includes services like search technology, customer support, analytics, authentication systems, and Services hosting. We have contracts with our service providers that ensure the safeguarding and proper use of your personal information.
                    <br /><b>•</b> We may share your personal information with our marketing partners who are sponsors of events, webinars etc. for which you register, or other parties with whom we may engage in joint marketing activities.
                    <br /><b>•</b> We may share your personal information with public or government authorities to follow applicable law or to respond to the legal process. We may also share your personal information when there are threats to the physical safety of any person, violations of this Privacy Policy or other agreements, or to protect the legal rights of third parties, including our employees, users, or the public.
                    <br /><b>•</b> We may share your personal information during corporate transaction like merger, or sale of our assets, investment or as part of the due diligence for such contemplated transactions.
                    <br /><b>•</b> We may share your personal information in other ways if you have asked us to do so or have given consent.
                </p>
                <p><b>c.</b>
                    Your personal information may also be shared as described below:
                    <br /><b>•</b> When you comment on our blogs or in our community forum, this information may also be read, collected, and used by others.
                    <br /><b>•</b> Your profile information may be viewed by other BillNama users with whom you collaborate.
                </p>

                <h3>Do we take backup of data?</h3>
                <p>es, We are taking daily one time backup using Firebase backup service. So incase of accident loss of data, we can restore lost data from last day backup. For that please contact at support@billnama.com when you face any such loss of data.
                </p>
                <h3>Your Choices</h3>
                <p>You can take the following actions to change or limit the collection or use of your personal information.<br />You may not fill out all details in your profile or account except a few necessary ones. <br />Right now we are not sending any marketing mail so there is a choice to opt out the mail.<br />You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. If you disable or refuse cookies, please note that some parts of the Services may then be inaccessible or not function properly.<br />If you do not want us to see your device location, you can turn off location sharing on your device. <br />You can close your account and request for deletion of the data. If user really want to delete its personal data from our Firebase database then please follow the following link:
                    <a href="https://billnama.com/data-deletion-instruction" target="_blank" style={{ color: "blue" }}>https://billnama.com/data_deletion</a>
                    <br />
                    You can send in your questions or complain by writing to us at support@billnama.com regarding our data use practices or Privacy Policy. We are committed to resolving all valid complaints about your privacy and our collection and use of your personal information.
                </p>

                <h3>What are your rights?</h3>
                <p>Under the GDPR you, as an individual, have certain rights, depending on our reason for processing of your personal data:

                    <br /><h3>1. Your right of access</h3>
                    <br />You have the right to request from us a confirmation whether we process any of your data, and where that is the case, you can access that data and the information regarding the purpose of the processing, the categories of personal data concerned and rest of the information concerning that processing. Based on this right, you have the right to ask us for copies of your personal data.

                    <br /><h3>2. Your right to rectification</h3>
                    If you notice that we have some incomplete or incorrect data, you can request from us to complete or correct it. This right always applies.
                    <br /> <br />However, if you are a user of our product, you may be able to login to your account and complete/correct it yourself. If this option is available, we strongly advise you to keep your personal data accurate at all times. However, if this option is not available to you, you can request from us to complete or correct it by contacting us. You can find our contact details in the section “How can you contact us” below.
                    <br /> <h3>3. Your right to erasure</h3>
                    Under certain circumstances you have the right to ask us to erase your personal data. For example, if you think that we no longer need your data for the original reason we collected or used it for, or you initially consented to us using your data, but have now withdrawn your consent, or you have objected to the use of your data for direct marketing purposes, or if you think that we have a legal obligation to erase your data.
                    <br /><br />However, please bear in mind that this is not an absolute right and that sometimes we will not be able to comply with your request. For example, if we have an overriding legitimate interest for the processing or where due to legal obligations, we must keep your data for a certain period.
                    <br /> <h3>4. Your right to restriction of processing</h3>
                    Under certain circumstances, if you are concerned about the accuracy of the data or how it is being used you have the right to ask us to restrict the processing of your information.
                    <br /> <h3>5. Your right to object to processing</h3>
                    You have a right to object to the processing of your personal data which is based on our legitimate interest (for example, such as the processing for the purpose of promotion or sales, or for statistical purposes).
                    <br /><h3>6. Your right to data portability</h3>
                    When the processing is based on your consent or required for the performance of a contract and when the processing is automated you have the right to ask that we transfer your data to another organization in a structured, commonly used and machine-readable format.
                    <br />This only applies to the personal data you manually provided to us.
                    <br /><h3>7. Your right to withdraw consent at any time</h3>
                    If the processing of personal data is based on your consent, you may at any time withdraw your consent and, in that case, we will no longer process your personal data for this purpose. However, withdrawal of consent will not affect the legality of the consent-based processing prior to its withdrawal.
                    <br /><h3>8. Your right to file a complaint to a supervisory authority</h3>
                    Lastly, if you think that the processing of personal data carried out by us is not compliant with GDPR, you have the right to file a complaint to the competent supervisory authority – in particular in the Member State of your habitual residence, place of work or place of the alleged infringement.
                    <br />However, without prejudice to your right to file a complaint to a supervisory authority, in case you are unsatisfied with our processing, we encourage you to first contact us to see if we may mutually resolve the issue. You can find our contact details in the section “How to Contact Us”
                </p>

                <h3>Protecting Your Personal Information</h3>
                <p>We have implemented reasonable administrative (organizational), physical, and technical safeguards intended to secure your personal information from accidental loss and from unauthorized access, use, alteration, disclosure, and other processing. Unfortunately, the transmission of information via the Internet is not completely secure. Although our security architecture is intended to protect the security of your personal information, we cannot guarantee the security of your personal information. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on the Services.</p>

                <h3>Children Under The Age Of 18</h3>
                <p>
                    Our Services is not intended for children under 18 years of age. No one under age 18 may provide any information to or on the Services. We do not knowingly collect personal information from children under 18. If you are under 18, do not use or provide any information on the Service or through any of its features, register on the Services, use any of the social media/comment features of the Services, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or username you may use. If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 18, please write to us at support@billnama.com California residents under 18 years of age may have additional rights regarding the sale of their personal information and the right of removal of content or information posted by minors.
                </p>

                <h3>Data Controller And Data Processor; Data Protection Officer</h3>
                <p>
                    With regard to personal information, we may collect from you or that you may provide, we process that personal information as “personal data” as a “controller,” as such terms are defined in the General Data Protection Regulation (“GDPR”). BillNama has a Data Protection Officer (as defined in the GDPR) who is responsible for matters relating to privacy and data protection. Our Data Protection Officer can be reached at support@billnama.com (Attn : Chirag Lukhi)
                    <br /><br /> For users located in the European Economic Area (EEA), all processing of Personal Data is performed in accordance with the GDPR.
                    <br /><br /> For users located in Brazil, all processing of Personal Data is performed in accordance with LGPD.
                    <br /><br /> All personal information collected by BillNama through the Services are stored exclusively in secure hosting facilities provided by Namecheap server and Google Firebase. BillNama has a data processing agreement in place with its providers, ensuring compliance with the GDPR and all applicable regulations. All hosting is performed in accordance with industry-standard security controls. All transfers of data from the EEA are done in accordance with regulations.
                </p>
                <h3>How do we charge users?</h3>
                <p>
                    This application is free to download but if you want to remove advertisements or other limitations then charge are as below:
                    <br /><br />For Indian customer:
                    <br />Monthly: ₹219
                    <br />Yearly: ₹1799
                    <br /><br />For Non-Indian customer:
                    <br />Monthly: $2.49 USD
                    <br />Yearly: $19.99
                    <br /><br />From website (Only for Indian customer):
                    <br />Monthly: ₹149
                    <br />Yearly: ₹1449
                </p>
                <h3>Privacy Policy Changes</h3>
                <p>Although most changes are likely to be minor, “BillNama: Invoice Maker, GST” application may change its Privacy Policy from time to time, and at its sole discretion. We encourage visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this app after any change in this Privacy Policy will constitute your acceptance of such change.</p>
                <h3>How To Contact Us</h3>
                <p>
                    If you have any questions regarding this Privacy policy or if you wish to exercise one of your GDPR rights stated above in section “What are your rights?”, feel free to contact us by sending an email to support@billnama.com
                    <br /><br />
                    When you contact us to exercise one of your GDPR rights, we might ask for additional information from you for the purpose of a reliable identification. If we cannot reliably identify you, please bear in mind that we can decline any request you make.
                    <br /> <br />
                    We will try to provide you the information you request within fifteen days of the receipt of your request.
                    <br /><br />
                    Please note that this Privacy policy primarily covers data processing in cases where we act as data controllers. Due to the nature of our services, we also act as data processors for third-parties (our clients) as data controllers. In such cases, we are generally required to refrain from addressing your privacy requests except as instructed by the data controllers. Nevertheless, should you direct your query to us, we will immediately notify your data controller and provide all necessary assistance in responding.
                </p>
                <p>
                    This privacy policy also matters for following mobile applications:
                </p>
                <p>Appstore: <Link href="https://apps.apple.com/app/invoice-maker-bill-generator/id1444998056" target="_blank" style={{ color: "blue" }}>https://apps.apple.com/app/invoice-maker-bill-generator/id1444998056</Link></p>
                <p>Playstore: <Link href="https://play.google.com/store/apps/details?id=com.softnoesis.invoice" target="_blank" style={{ color: "blue" }}> https://play.google.com/store/apps/details?id=com.softnoesis.invoice</Link></p>

            </div>
            <Footer />
        </>
    );
}